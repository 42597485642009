/* eslint-disable no-prototype-builtins */
import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth-module';
import bankAccounts from './modules/bank-accounts-module';
import employeeIssueStatistics from './modules/employee-issue-statistics-module';
import invoices from './modules/invoices-module';
import plannedFinanceFlows from './modules/planned-finance-flows-module';
import projects from './modules/projects-module';
import projectDocumentations from './modules/project-documentations-module';
import projectIssues from './modules/project-issues-module';
import projectBurnUpStatistics from './modules/project-burnup-statistics-module';
import projectPointStatistics from './modules/project-point-statistics-module';
import projectSprints from './modules/project-sprints-module';
import projectTimeSpentStatistics from './modules/project-time-spent-statistics-module';
import projectUserStories from './modules/project-user-stories-module';
import projectWarranties from './modules/project-warranties-module';
import projectIssueCreatedByStatistics from './modules/project-issue-created-by-statistics-module';
import projectIssueStatistics from './modules/project-issue-statistics-module';
import documents from './legacy-modules/documents/documents';
import clientAgreements from './modules/client-agreements-module';
import commercialOffers from './modules/commercial-offers-module';
import companyRequests from './modules/company-requests-module';
import employeeDocuments from './modules/employee-documents-module';
import internalDocuments from './modules/internal-documents-module';
import receivedInvoices from './modules/received-invoices-module';
import sentInvoices from './modules/sent-invoices-module';
import supplierAgreements from './modules/supplier-agreements-module';
import shortcuts from './modules/shortcuts-module';
import notifications from './modules/notifications-module';
import companyManagement from './legacy-modules/company-management/company-management';
import employees from './legacy-modules/employees/employees';
import progress from './modules/progress-module';
import settings from './modules/settings-module';
import timeTracking from './modules/time-tracking-module';
import users from './modules/users-module';
import issueStatusesStatistics from './modules/project-issue-statuses-statistics-module';
import todos from './modules/todos-module';
import infrastructures from './modules/infrastructures-module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    auth,
    bankAccounts,
    employeeIssueStatistics,
    invoices,
    plannedFinanceFlows,
    projects,
    projectDocumentations,
    projectIssues,
    projectIssueCreatedByStatistics,
    projectBurnUpStatistics,
    projectIssueStatistics,
    projectPointStatistics,
    projectSprints,
    projectTimeSpentStatistics,
    projectUserStories,
    projectWarranties,
    documents,
    clientAgreements,
    commercialOffers,
    companyRequests,
    employeeDocuments,
    internalDocuments,
    receivedInvoices,
    sentInvoices,
    supplierAgreements,
    shortcuts,
    notifications,
    companyManagement,
    employees,
    progress,
    settings,
    timeTracking,
    users,
    issueStatusesStatistics,
    todos,
    infrastructures,
  },
});
