<template>
  <div>
    <BaseFileViewer v-model="isOpen" :name="name" :file-url="url" :document-id="documentId" />
  </div>
</template>

<script>
import BaseFileViewer from './base/BaseFileViewer.vue';
import eventBus, { FILE_OPENED, OPEN_FILE, openSnackbar } from '../util/event-bus';

export default {
  name: 'GlobalFileViewer',

  components: { BaseFileViewer },

  data() {
    return {
      isOpen: false,
      name: '',
      url: '',
      documentId: null,
    };
  },

  mounted() {
    eventBus.$on(OPEN_FILE, this.handleFile);
  },

  beforeDestroy() {
    eventBus.$off(OPEN_FILE);
  },

  methods: {
    downloadFile(fileName, url) {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      eventBus.$emit(FILE_OPENED);
    },

    openFileInViewer(name, url, documentId) {
      this.name = name;
      this.url = url;
      this.documentId = documentId;
      this.isOpen = true;
      eventBus.$emit(FILE_OPENED);
    },

    async fetchFile(url) {
      try {
        const { data } = await this.$http({
          url,
          method: 'GET',
          responseType: 'blob',
        });
        return data;
      } catch (e) {
        openSnackbar(this.$t('general.file_download_failed'));
        throw e;
      }
    },

    async handleFile(params) {
      const url = params.isPublic
        ? params.url
        : URL.createObjectURL(await this.fetchFile(params.url));

      const supportedTypes = ['pdf', 'png', 'jpg', 'jpeg', 'gif', 'bmp', 'mp4'];
      const name = params.name.toLowerCase();
      let isSupported = false;
      for (let i = 0; i < supportedTypes.length; i++) {
        if (name.includes(supportedTypes[i])) {
          isSupported = true;
          break;
        }
      }

      if (isSupported) {
        this.openFileInViewer(params.name, url, params.documentId);
      } else {
        this.downloadFile(params.name, url);
      }
    },
  },
};
</script>
