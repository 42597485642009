<template>
  <div>
    <div class="d-flex align-center py-2">
      <div v-if="showChips" class="pr-1 d-flex justify-end flex-wrap">
        <v-chip
          v-for="value in submittedValues"
          :key="`${value.key}-${value.value}`"
          style="margin-bottom: 2px"
          close
          @click:close="clearFilter(value.key, value.value)"
        >
          <template v-if="value.label">{{ value.label }}</template>
          <template v-if="value.value">: {{ value.translatedValue || value.value }}</template>
        </v-chip>
      </div>

      <v-tooltip v-if="showResetButton" open-delay="500" bottom>
        <template #activator="{ on }">
          <v-btn v-on="on" icon @click.stop="resetFilters">
            <v-icon>autorenew</v-icon>
          </v-btn>
        </template>
        <div>
          {{ $t('general.reset_filters') }}
        </div>
      </v-tooltip>

      <v-tooltip open-delay="500" bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon class="ma-0" @click.stop="openFilterDialog">
            <v-badge
              :value="submittedValues.length > 0 && !showChips"
              :content="submittedValues.length"
              color="error"
              overlap
            >
              <v-icon>filter_list</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <div>
          {{ $t('general.filters.filter') }}
        </div>
      </v-tooltip>
    </div>

    <!-- Mobile -->
    <BaseNestableBottomSheet
      v-if="$vuetify.breakpoint.mdAndDown"
      :value="isDialogOpen"
      max-width="800"
      scrollable
      eager
      @click:outside="closeFilterDialog"
    >
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="button" text @click="closeFilterDialog">
            {{ $t('general.controls.close') }}
          </v-btn>
          <v-btn type="button" color="primary" text @click="submitFilters">
            {{ $t('general.filters.filter') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </BaseNestableBottomSheet>

    <!-- Desktop -->
    <BaseNestableDialog
      v-else
      :value="isDialogOpen"
      max-width="800"
      scrollable
      eager
      @click:outside="closeFilterDialog"
    >
      <v-card>
        <v-card-title>
          {{ dialogTitle }}
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="button" text @click="closeFilterDialog">
            {{ $t('general.controls.close') }}
          </v-btn>
          <v-btn type="button" color="primary" text @click="submitFilters">
            {{ $t('general.filters.filter') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </BaseNestableDialog>
  </div>
</template>

<script>
import BaseNestableDialog from '@/components/base/BaseNestableDialog.vue';
import i18n from '@/i18n/i18n-config';
import BaseNestableBottomSheet from '@/components/base/BaseNestableBottomSheet.vue';

export default {
  name: 'BaseFilterDialogAndChips',

  components: { BaseNestableBottomSheet, BaseNestableDialog },

  props: {
    submittedValues: {
      type: Array,
      default: () => [],
    },

    hideChips: {
      type: Boolean,
      default: false,
    },

    showResetButton: {
      type: Boolean,
      default: false,
    },

    dialogTitle: {
      type: String,
      default: () => i18n.t('general.filters.filters'),
    },
  },

  data() {
    return {
      isDialogOpen: false,
    };
  },

  computed: {
    showChips() {
      return !this.hideChips && this.$vuetify.breakpoint.mdAndUp;
    },
  },

  methods: {
    openFilterDialog() {
      this.isDialogOpen = true;
      this.$emit('open');
    },

    closeFilterDialog() {
      this.isDialogOpen = false;
    },

    clearFilter(key, value) {
      this.$emit('clear:filter', key, value);
    },

    resetFilters() {
      this.$emit('reset');
    },

    submitFilters() {
      this.closeFilterDialog();
      this.$emit('submit');
    },
  },
};
</script>

<style scoped></style>
