import Vue from 'vue';
import projectUserStoryService from '@/api/project-user-story-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import clone from 'just-clone';
import projectAttachmentService from '@/api/project-attachment-service';

export const getDefaultProjectUserStoryFormItem = () => ({
  status: 'new',
  sprint_id: null,
});

const state = {
  projectUserStoriesBySprint: {}, // { {sprint_id}: [] }
  selectedProjectUserStory: {},
  newProjectUserStory: getDefaultProjectUserStoryFormItem(),
  projectUserStoryValidationErrors: {},

  selectedProjectUserStoriesMap: {},
  highlightedUserStoryId: null,
};

const getters = {
  projectUserStoryPointTypes() {
    return [
      {
        type: 'front_end',
        prefix: 'F',
        estimatedPointsFieldKey: 'estimated_front_end_points',
        completedPointsFieldKey: 'completed_front_end_points',
        statusFieldKey: 'front_end_point_status',
        label: 'Front end',
        darkThemeColor: '#fff7e5',
        lightThemeColor: '#ffebff',
      },
      {
        type: 'back_end',
        prefix: 'B',
        estimatedPointsFieldKey: 'estimated_back_end_points',
        completedPointsFieldKey: 'completed_back_end_points',
        statusFieldKey: 'back_end_point_status',
        label: 'Back end',
        darkThemeColor: '#ffd880',
        lightThemeColor: '#cfabd6',
      },
      {
        type: 'pm',
        prefix: 'M',
        estimatedPointsFieldKey: 'estimated_pm_points',
        completedPointsFieldKey: 'completed_pm_points',
        statusFieldKey: 'pm_point_status',
        label: i18n.t('projects.general.project_management'),
        darkThemeColor: '#ffb81a',
        lightThemeColor: '#9b70b0',
      },
      {
        type: 'qa',
        prefix: 'QA',
        estimatedPointsFieldKey: 'estimated_qa_points',
        completedPointsFieldKey: 'completed_qa_points',
        statusFieldKey: 'qa_point_status',
        label: 'QA',
        darkThemeColor: '#b37c00',
        lightThemeColor: '#64398e',
      },
      {
        type: 'design',
        prefix: 'D',
        estimatedPointsFieldKey: 'estimated_design_points',
        completedPointsFieldKey: 'completed_design_points',
        statusFieldKey: 'design_point_status',
        label: i18n.t('projects.general.design'),
        darkThemeColor: '#4d3500',
        lightThemeColor: '#1e036d',
      },
    ];
  },

  projectUserStoryStatuses() {
    return ['new', 'client_approved'].map((s) => ({
      value: s,
      text: i18n.t(`projects.user_story_status.${s}`),
    }));
  },

  projectUserStoryDeployStatuses() {
    return [
      {
        value: 'null',
        text: i18n.t('projects.user_story_deploy_statuses.null'),
        color: 'inherit',
        icon: '',
      },
      {
        value: 'staging',
        text: i18n.t('projects.user_story_deploy_statuses.staging'),
        color: '#03a9f4',
        icon: 'rocket_launch',
      },
      {
        value: 'production',
        text: i18n.t('projects.user_story_deploy_statuses.production'),
        color: 'success',
        icon: 'rocket_launch',
      },
    ];
  },

  projectUserStoryTestStatuses() {
    return [
      {
        value: 'null',
        text: i18n.t('projects.user_story_test_statuses.null'),
        color: 'inherit',
        icon: '',
      },
      {
        value: 'failed',
        text: i18n.t('projects.user_story_test_statuses.failed'),
        color: 'error',
        icon: 'rule',
      },
      {
        value: 'passed',
        text: i18n.t('projects.user_story_test_statuses.passed'),
        color: 'success',
        icon: 'checklist_rtl',
      },
    ];
  },
};

const mutations = {
  SET_PROJECT_USER_STORIES_BY_SPRINT(state, value) {
    state.projectUserStoriesBySprint = value;
    state.selectedProjectUserStoriesMap = {};
  },

  SET_PROJECT_SPRINT_USER_STORIES(state, { sprintId, userStories }) {
    Vue.set(state.projectUserStoriesBySprint, sprintId, userStories);
    state.selectedProjectUserStoriesMap = {};
  },

  SET_EDITED_PROJECT_USER_STORY(state, projectUserStory) {
    state.projectUserStoryValidationErrors = {};
    state.selectedProjectUserStory = clone(projectUserStory);
    state.selectedProjectUserStory.sprint_id = +state.selectedProjectUserStory.sprint_id || null;
  },

  SET_NEW_PROJECT_USER_STORY(state, projectUserStory) {
    state.newProjectUserStory = projectUserStory;
  },

  SET_HIGHLIGHTED_USER_STORY_ID(state, userStoryId) {
    state.highlightedUserStoryId = userStoryId;
  },

  STORE_PROJECT_USER_STORY(state, projectUserStory) {
    if (projectUserStory.sprint_id) {
      state.projectUserStoriesBySprint[projectUserStory.sprint_id].push(projectUserStory);
    } else {
      state.projectUserStoriesBySprint[projectUserStory.sprint_id].unshift(projectUserStory);
    }
    state.projectUserStoryValidationErrors = {};
    state.newProjectUserStory = getDefaultProjectUserStoryFormItem();
  },

  STORE_PROJECT_USER_STORIES(state, projectUserStories) {
    for (let i = 0; i < projectUserStories.length; i++) {
      const story = projectUserStories[i];
      if (story.sprint_id) {
        // different sorting in BE for inserting into sprint vs inserting as unassigned to sprint
        state.projectUserStoriesBySprint[story.sprint_id].push(story);
      } else {
        state.projectUserStoriesBySprint[story.sprint_id].unshift(story);
      }
    }
    state.projectUserStoryValidationErrors = {};
  },

  UPDATE_PROJECT_USER_STORY(state, updatedUserStory) {
    if (updatedUserStory.id === state.selectedProjectUserStory.id) {
      state.selectedProjectUserStory = {
        ...state.selectedProjectUserStory,
        ...updatedUserStory,
      };
    }

    const sprintIds = Object.keys(state.projectUserStoriesBySprint);
    for (let i = 0; i < sprintIds.length; i++) {
      const sprintId = sprintIds[i];
      for (let j = 0; j < state.projectUserStoriesBySprint[sprintId].length; j++) {
        const userStory = state.projectUserStoriesBySprint[sprintId][j];
        if (userStory.id === updatedUserStory.id) {
          if (+userStory.sprint_id !== +updatedUserStory.sprint_id) {
            state.projectUserStoriesBySprint[sprintId].splice(j, 1);
            state.projectUserStoriesBySprint[updatedUserStory.sprint_id].push(updatedUserStory);
          } else {
            state.projectUserStoriesBySprint[sprintId].splice(j, 1, updatedUserStory);
          }
          return;
        }
      }
    }
  },

  DELETE_PROJECT_USER_STORY(state, projectUserStory) {
    if (state.projectUserStoriesBySprint[projectUserStory.sprint_id]) {
      state.projectUserStoriesBySprint[projectUserStory.sprint_id] = removeArrayItem(
        state.projectUserStoriesBySprint[projectUserStory.sprint_id],
        projectUserStory
      );
    }
    if (state.selectedProjectUserStoriesMap[projectUserStory.id]) {
      Vue.delete(state.selectedProjectUserStoriesMap, projectUserStory.id);
    }
  },

  SET_PROJECT_USER_STORY_VALIDATION_ERRORS(state, projectUserStoryValidationErrors) {
    state.projectUserStoryValidationErrors = projectUserStoryValidationErrors;
  },

  CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.projectUserStoryValidationErrors, field);
  },

  SET_SELECTED_PROJECT_USER_STORIES_MAP(state, payload) {
    state.selectedProjectUserStoriesMap = payload;
  },

  TOGGLE_SELECTED_PROJECT_USER_STORY(state, userStoryId) {
    if (state.selectedProjectUserStoriesMap[userStoryId]) {
      Vue.delete(state.selectedProjectUserStoriesMap, userStoryId);
    } else {
      Vue.set(state.selectedProjectUserStoriesMap, userStoryId, true);
    }
  },

  UPDATE_SELECTED_PROJECT_USER_STORIES_FIELDS(state, updatedFields) {
    const sprintIds = Object.keys(state.projectUserStoriesBySprint);
    for (let i = 0; i < sprintIds.length; i++) {
      const sprintId = sprintIds[i];
      for (let j = 0; j < state.projectUserStoriesBySprint[sprintId].length; j++) {
        const userStory = state.projectUserStoriesBySprint[sprintId][j];
        if (state.selectedProjectUserStoriesMap[userStory.id]) {
          const updatedUserStory = {
            ...userStory,
            ...updatedFields,
          };
          state.projectUserStoriesBySprint[sprintId].splice(j, 1, updatedUserStory);
        }
      }
    }
  },

  UPDATE_SELECTED_PROJECT_USER_STORIES_DEPLOY_STATUS(state, newDeployStatus) {
    const sprintIds = Object.keys(state.projectUserStoriesBySprint);
    for (let i = 0; i < sprintIds.length; i++) {
      const sprintId = sprintIds[i];
      for (let j = 0; j < state.projectUserStoriesBySprint[sprintId].length; j++) {
        const userStory = state.projectUserStoriesBySprint[sprintId][j];
        if (!state.selectedProjectUserStoriesMap[userStory.id]) {
          continue;
        }

        let isNewDeployment = true;
        const deployStatuses = userStory.deploy_statuses;
        for (let k = 0; k < deployStatuses.length; k++) {
          if (deployStatuses[k].git_repo_url === newDeployStatus.git_repo_url) {
            if (!newDeployStatus.status) {
              // user story deployment status was reset to null, which means its no longer deployed
              deployStatuses.splice(k, 1);
            } else {
              deployStatuses.splice(k, 1, newDeployStatus);
            }
            isNewDeployment = false;
            break;
          }
        }
        if (isNewDeployment) {
          deployStatuses.unshift(newDeployStatus);
        }

        state.projectUserStoriesBySprint[sprintId].splice(j, 1, {
          ...userStory,
          deploy_statuses: deployStatuses,
        });
      }
    }
  },
};

const actions = {
  storeProjectUserStory({ commit }, projectUserStory) {
    return projectUserStoryService
      .create(projectUserStory)
      .then((res) => {
        commit('STORE_PROJECT_USER_STORY', res.data);
        openSnackbar(i18n.t('projects.user_story_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  storeProjectUserStoriesBulk({ commit }, payload) {
    return projectUserStoryService
      .createBulk(payload)
      .then((res) => {
        commit('STORE_PROJECT_USER_STORIES', res.data);
        openSnackbar(i18n.t('projects.user_stories_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editProjectUserStory({ state, commit }, projectUserStoryId) {
    if (state.selectedProjectUserStory.id === projectUserStoryId) {
      return Promise.resolve(state.selectedProjectUserStory);
    }

    const findUserStory = () => {
      const sprintIds = Object.keys(state.projectUserStoriesBySprint);
      for (let i = 0; i < sprintIds.length; i++) {
        const userStories = state.projectUserStoriesBySprint[sprintIds[i]];
        for (let j = 0; j < userStories.length; j++) {
          if (userStories[j].id === projectUserStoryId) {
            return userStories[j];
          }
        }
      }
      return null;
    };
    const projectUserStory = findUserStory();

    if (projectUserStory) {
      commit('SET_EDITED_PROJECT_USER_STORY', projectUserStory);
      return Promise.resolve(projectUserStory);
    }
    return projectUserStoryService.getById(projectUserStoryId).then((res) => {
      commit('SET_EDITED_PROJECT_USER_STORY', res.data);
      return res.data;
    });
  },

  updateProjectUserStory({ commit }, projectUserStory) {
    return projectUserStoryService
      .update(projectUserStory)
      .then((res) => {
        commit('UPDATE_PROJECT_USER_STORY', {
          ...projectUserStory,
          ...res.data,
        });
        openSnackbar(i18n.t('projects.user_story_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  updateProjectUserStoryDescription({ commit }, { projectUserStory, description }) {
    return projectUserStoryService
      .updateDescription(projectUserStory.id, description)
      .then((res) => {
        commit('UPDATE_PROJECT_USER_STORY', {
          ...projectUserStory,
          ...res.data,
        });
        openSnackbar(i18n.t('projects.user_story_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async moveProjectUserStory({ state, commit, dispatch }, event) {
    const projectUserStoriesBySprintBackup = clone(state.projectUserStoriesBySprint);

    try {
      const projectUserStoriesBySprint = clone(state.projectUserStoriesBySprint);
      const movedUserStoryId = +event.item.attributes['data-user-story-id'].value;
      const oldSprintId = +event.item.attributes['data-sprint-id']?.value || null;
      const newSprintId = +event.to.attributes['data-sprint-id']?.value || null;
      const { oldIndex, newIndex } = event;

      if (oldSprintId === newSprintId && oldIndex === newIndex) {
        return;
      }

      const movedUserStory = projectUserStoriesBySprint[oldSprintId].find(
        (s) => s.id === movedUserStoryId
      );
      if (!movedUserStory) {
        throw new Error('User story not found');
      }

      movedUserStory.sprint_id = newSprintId;
      const insertAfter = projectUserStoriesBySprint[newSprintId][newIndex - 1]?.id || null;
      if (!newSprintId && oldSprintId) {
        projectUserStoryService.removeFromSprint(movedUserStory, insertAfter);
      } else if (newSprintId && !oldSprintId) {
        projectUserStoryService.addToSprint(movedUserStory, insertAfter, newSprintId);
      } else if (newSprintId !== oldSprintId) {
        projectUserStoryService.addToSprint(movedUserStory, insertAfter, newSprintId);
      }

      projectUserStoriesBySprint[oldSprintId].splice(oldIndex, 1);
      projectUserStoriesBySprint[newSprintId].splice(newIndex, 0, movedUserStory);
      commit('SET_PROJECT_USER_STORIES_BY_SPRINT', projectUserStoriesBySprint);

      await dispatch('saveUserStoriesOrder', newSprintId);
    } catch (e) {
      commit('SET_PROJECT_USER_STORIES_BY_SPRINT', projectUserStoriesBySprintBackup);
      openSnackbar(i18n.t('projects.failed_to_move_user_story'));
      throw e;
    }
  },

  async saveUserStoriesOrder({ state }, sprintId) {
    await projectUserStoryService.reorder(state.projectUserStoriesBySprint[sprintId]);
  },

  async moveSelectedProjectUserStories({ state, commit, dispatch }, { projectId, sprintId }) {
    let response;
    try {
      response = await projectUserStoryService.move(
        Object.keys(state.selectedProjectUserStoriesMap),
        projectId,
        sprintId
      );
    } catch (e) {
      commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(e));
      throw e;
    }

    const movedUserStories = response.data;
    const movedUserStoriesIdsSet = new Set(movedUserStories.map((s) => s.id));
    const projectUserStoriesBySprint = clone(state.projectUserStoriesBySprint);
    const sprintIds = Object.keys(projectUserStoriesBySprint);

    for (let i = 0; i < sprintIds.length; i++) {
      const userStories = projectUserStoriesBySprint[sprintIds[i]];
      for (let j = 0; j < userStories.length; j++) {
        if (movedUserStoriesIdsSet.has(userStories[j].id)) {
          userStories.splice(j, 1);
          j -= 1;
        }
      }
    }

    projectUserStoriesBySprint[sprintId] = [
      ...(projectUserStoriesBySprint[sprintId] || []),
      ...movedUserStories,
    ];

    commit('SET_PROJECT_USER_STORIES_BY_SPRINT', projectUserStoriesBySprint);
    openSnackbar(i18n.t('projects.general.stories_moved'));
    dispatch('saveUserStoriesOrder', sprintId);

    return response.data;
  },

  async updateProjectUserStoriesDeployStatus({ rootState, commit }, payload) {
    try {
      const response = await projectUserStoryService.massUpdateDeployStatus(payload);
      commit('UPDATE_SELECTED_PROJECT_USER_STORIES_DEPLOY_STATUS', {
        deployed_by: rootState.auth.currentUser.id,
        status: payload.status,
        git_repo_url: payload.git_repo_url,
      });
      openSnackbar(i18n.t('projects.user_stories_deploy_status_updated'));
      localStorage.setItem(
        'USUpdateOptions',
        JSON.stringify({ git_repo_url: payload.git_repo_url, status: payload.status })
      );

      return response.data;
    } catch (err) {
      commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
      throw err;
    }
  },

  async updateProjectUserStoriesTestStatus({ rootState, commit }, payload) {
    try {
      const response = await projectUserStoryService.massUpdateTestStatus(payload);
      commit('UPDATE_SELECTED_PROJECT_USER_STORIES_FIELDS', {
        tested_by: rootState.auth.currentUser.id,
        test_status: payload.test_status,
      });
      openSnackbar(i18n.t('projects.user_stories_test_status_updated'));
      return response.data;
    } catch (err) {
      commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
      throw err;
    }
  },

  async updateProjectUserStoryPointsStatus({ commit }, { projectUserStory, pointsType }) {
    const statusMap = {
      in_progress: 'closed',
      closed: null,
    };

    const currentStatus = projectUserStory[pointsType];
    // if current status is null, user story is new
    const newStatus = currentStatus ? statusMap[currentStatus] : 'in_progress';

    try {
      const { data } = await projectUserStoryService.updatePointsStatus(
        projectUserStory,
        pointsType,
        newStatus
      );
      commit('UPDATE_PROJECT_USER_STORY', data);
    } catch (e) {
      // usually 403, when trying to change status of points assigned to another employee.
      // Generic 403 error message is deemed good enough for that.
    }
  },

  async uploadProjectUserStoryAttachments({ commit }, { projectUserStory, attachments }) {
    try {
      const { data } = await projectAttachmentService.uploadProjectUserStoryAttachments(
        attachments,
        projectUserStory
      );

      const updatedUserStory = clone(projectUserStory);
      updatedUserStory.attachments = [...updatedUserStory.attachments, ...data];
      commit('UPDATE_PROJECT_USER_STORY', updatedUserStory);
    } catch (e) {
      console.log(e);
      openSnackbar(i18n.t('file_upload_failed'));
    }
  },

  async deleteProjectUserStory({ commit }, projectUserStory) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    });
    if (!confirmed) {
      return false;
    }

    await projectUserStoryService.delete(projectUserStory);
    commit('DELETE_PROJECT_USER_STORY', projectUserStory);
    openSnackbar(i18n.t('projects.user_story_deleted'));
    return true;
  },

  async deleteProjectUserStoryAttachment({ commit }, { projectUserStory, attachment }) {
    await projectAttachmentService.delete(attachment);
    const updatedUserStory = clone(projectUserStory);
    updatedUserStory.attachments = updatedUserStory.attachments.filter(
      (a) => a.id !== attachment.id
    );
    commit('UPDATE_PROJECT_USER_STORY', updatedUserStory);
    openSnackbar(i18n.t('general.file_deleted'));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
