import { permissionsGuard, roleGuard } from '@/router/guards';
import store from '@/store';

export const projectRoutes = [
  {
    path: 'projects',
    redirect: { name: 'projectsList' },
  },
  {
    path: 'client-projects',
    name: 'client-projects',
    meta: { requiredPermissions: ['projects.view.*'] },
    component: () =>
      import(/* webpackChunkName: "client-projects" */ '../../views/projects/ClientProjects.vue'),
    beforeEnter: permissionsGuard,
  },
  {
    path: 'projects',
    name: 'projects',
    component: () =>
      import(/* webpackChunkName: "projectsTabs" */ '@/views/projects/ProjectsTabs.vue'),
    beforeEnter: permissionsGuard,
    redirect: () => ({ name: 'projectsList', query: store.state.projects.projectFilterParams }),
    meta: {
      menuGroup: 'project-management',
      requiredPermissions: ['projects.view.*'],
    },
    children: [
      {
        path: 'list',
        name: 'projectsList',
        component: () =>
          import(/* webpackChunkName: "projectsList" */ '@/views/projects/Projects.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
        children: [
          {
            path: 'create',
            name: 'createProject',
            component: () =>
              import(/* webpackChunkName: "createProject" */ '@/views/projects/CreateProject.vue'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.create.*'],
              menuGroup: 'project-management',
              getBackRoute: () => ({
                name: 'projectsList',
                query: store.state.projects.projectFilterParams,
              }),
            },
          },
          {
            path: ':projectId/edit',
            name: 'editProject',
            component: () =>
              import(/* webpackChunkName: "editProject" */ '@/views/projects/EditProject.vue'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.create.*'],
              menuGroup: 'project-management',
              getBackRoute: () => ({
                name: 'projectsList',
                query: store.state.projects.projectFilterParams,
              }),
            },
          },
        ],
      },
      {
        path: 'issues',
        name: 'projectIssues',
        component: () =>
          import(
            /* webpackChunkName: "projectIssues" */ '@/views/project-issues/ProjectIssues.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
        children: [
          {
            path: 'create',
            name: 'createProjectIssue',
            component: () =>
              import(
                /* webpackChunkName: "createProjectIssue" */ '@/views/project-issues/CreateProjectIssue.vue'
              ),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
              getBackRoute: () => ({
                name: 'projectIssues',
                query: store.state.projectIssues.projectIssueFilterParams,
              }),
            },
          },
          {
            path: ':projectIssueId/edit',
            name: 'editProjectIssue',
            component: () =>
              import(
                /* webpackChunkName: "editProjectIssue" */ '@/views/project-issues/EditProjectIssue.vue'
              ),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
              getBackRoute: () => ({
                name: 'projectIssues',
                query: store.state.projectIssues.projectIssueFilterParams,
              }),
            },
          },
          {
            path: ':projectIssueId/conclude',
            name: 'concludeProjectIssue',
            component: () =>
              import(
                /* webpackChunkName: "concludeProjectIssue" */ '@/views/project-issues/ConcludeProjectIssue.vue'
              ),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
              getBackRoute: () => ({
                name: 'projectIssues',
                query: store.state.projectIssues.projectIssueFilterParams,
              }),
            },
          },
        ],
      },
      {
        path: 'issue-conclusions',
        name: 'projectIssueConclusions',
        component: () =>
          import(
            /* webpackChunkName: "projectIssueConclusions" */ '@/views/project-issues/ProjectIssueConclusions.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
    ],
  },
  {
    path: 'project/:projectId',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '@/views/projects/Project.vue'),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'project-management',
      requiredPermissions: ['projects.view.*'],
      getBackRoute: () => ({
        name: 'projectsList',
        query: store.state.projects.projectFilterParams,
      }),
    },
    children: [
      {
        path: 'edit',
        name: 'project_editProject',
        component: () =>
          import(/* webpackChunkName: "editProject" */ '@/views/projects/EditProject.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.create.*'],
          menuGroup: 'project-management',
          getBackRoute: (to) => ({
            name: 'project',
            params: { projectId: to.params.projectId },
          }),
        },
      },
      {
        path: 'issues/create',
        name: 'project_createProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "createProjectIssue" */ '@/views/project-issues/CreateProjectIssue.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'issues/:projectIssueId/edit',
        name: 'project_editProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "editProjectIssue" */ '@/views/project-issues/EditProjectIssue.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'issues/:projectIssueId/conclude',
        name: 'project_concludeProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "concludeProjectIssue" */ '@/views/project-issues/ConcludeProjectIssue.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'issues/:projectIssueId/convert-to-user-story',
        name: 'createProjectUserStoryFromIssue',
        component: () =>
          import(
            /* webpackChunkName: "createProjectUserStoryFromIssue" */ '@/views/project-user-stories/CreateProjectUserStoryFromIssue.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'configurations',
        name: 'projectConfigurations',
        component: () =>
          import(
            /* webpackChunkName: "projectConfigurations" */ '@/views/projects/ProjectConfigurations.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'statistics',
        name: 'projectStatistics',
        component: () =>
          import(
            /* webpackChunkName: "projectStatistics" */ '@/views/project-statistics/ProjectPageStatisticsTabs.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
        children: [
          {
            path: 'points',
            name: 'projectPointStatistics',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "projectPointStatistics" */ '@/views/project-statistics/ProjectPointStatistics.vue'
              ),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
            },
          },
          {
            path: 'time-spent',
            name: 'projectTimeSpentStatistics',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "projectTimeSpentStatistics" */ '@/views/project-statistics/ProjectTimeSpentStatistics.vue'
              ),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
            },
          },
          {
            path: 'burn-up',
            name: 'projectBurnUpStatistics',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "projectBurnUpStatistics" */ '@/views/project-statistics/ProjectBurnUpStatistics.vue'
              ),
            beforeEnter: roleGuard,
            meta: {
              allowedRoles: ['admin'],
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
            },
          },
        ],
      },
      {
        path: 'my-settings',
        name: 'projectSettings',
        component: () =>
          import(/* webpackChunkName: "projectSettings" */ '@/views/projects/ProjectSettings.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'team',
        name: 'projectTeam',
        component: () =>
          import(/* webpackChunkName: "projectTeam" */ '@/views/projects/ProjectTeam.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'move-selected-user-stories',
        name: 'moveSelectedUserStories',
        component: () =>
          import(
            /* webpackChunkName: "moveSelectedUserStories" */ '@/views/project-user-stories/MoveProjectUserStories.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'update-selected-user-stories-deploy-status',
        name: 'updateSelectedProjectUserStoriesDeployStatus',
        component: () =>
          import(
            /* webpackChunkName: "updateSelectedProjectUserStoriesDeployStatus" */ '@/views/project-user-stories/UpdateSelectedProjectUserStoriesDeployStatus.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'update-selected-user-stories-test-status',
        name: 'updateSelectedProjectUserStoriesTestStatus',
        component: () =>
          import(
            /* webpackChunkName: "updateSelectedProjectUserStoriesTestStatus" */ '@/views/project-user-stories/UpdateSelectedProjectUserStoriesTestStatus.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'sprints/create',
        name: 'createProjectSprint',
        component: () =>
          import(
            /* webpackChunkName: "createProjectSprint" */ '@/views/project-sprints/CreateProjectSprint.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'sprints/:projectSprintId',
        name: 'editProjectSprint',
        component: () =>
          import(
            /* webpackChunkName: "editProjectSprint" */ '@/views/project-sprints/EditProjectSprint.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'user-stories/create',
        name: 'createProjectUserStory',
        component: () =>
          import(
            /* webpackChunkName: "createProjectUserStory" */ '@/views/project-user-stories/CreateProjectUserStory.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          getBackRoute: () => ({
            name: 'project',
          }),
        },
      },
      {
        path: 'user-stories/create-bulk',
        name: 'createProjectUserStoryBulk',
        component: () =>
          import(
            /* webpackChunkName: "createProjectUserStoryBulk" */ '@/views/project-user-stories/CreateProjectUserStoriesInBulk.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'user-stories/:projectUserStoryId',
        name: 'editProjectUserStory',
        component: () =>
          import(
            /* webpackChunkName: "editProjectUserStory" */ '@/views/project-user-stories/EditProjectUserStory.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          getBackRoute: (currentRoute) => ({
            name: 'project',
            params: {
              projectId: currentRoute.params.projectId,
            },
          }),
        },
      },
      {
        path: 'sprints/:sprintId/summary',
        name: 'projectSprintUserStoriesSummary',
        component: () =>
          import(
            /* webpackChunkName: "projectSprintUserStoriesSummary" */ '@/views/project-sprints/ProjectSprintUserStoriesSummary.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'unassigned-user-stories-summary',
        name: 'unassignedUserStoriesSummary',
        component: () =>
          import(
            /* webpackChunkName: "projectSprintUserStoriesSummary" */ '@/views/project-sprints/ProjectSprintUserStoriesSummary.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'documentations/create',
        name: 'createProjectDocumentation',
        component: () =>
          import(
            /* webpackChunkName: "createProjectDocumentation" */ '@/views/project-documentations/CreateProjectDocumentation.vue'
          ),
        meta: { menuGroup: 'project-management', requiredPermissions: ['projects.view.*'] },
      },
      {
        path: 'documentations/:projectDocumentationId/edit',
        name: 'editProjectDocumentation',
        component: () =>
          import(
            /* webpackChunkName: "editProjectDocumentation" */ '@/views/project-documentations/EditProjectDocumentation.vue'
          ),
        meta: { menuGroup: 'project-management', requiredPermissions: ['projects.view.*'] },
      },

      // steps after closing a sprint
      // --------------------------------
      {
        path: 'create-google-doc',
        name: 'project_createClientAgreementGoogleDoc',
        component: () =>
          import(
            /* webpackChunkName: "createClientAgreementGoogleDoc" */ '@/views/client-agreements/CreateClientAgreementGoogleDoc.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['documents.client_agreement.view.*'],
          getBackRoute: () => ({
            name: 'project_createProjectWarranty',
          }),
        },
      },
      {
        path: 'create-project-warranty',
        name: 'project_createProjectWarranty',
        component: () =>
          import(
            /* webpackChunkName: "createProjectWarranty" */ '@/views/project-warranties/CreateProjectWarranty.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          getBackRoute: () => ({
            name: 'project_createInvoice',
          }),
        },
      },
      {
        path: 'create-invoice',
        name: 'project_createInvoice',
        component: () =>
          import(/* webpackChunkName: "createInvoice" */ '@/views/invoices/CreateInvoice.vue'),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          getBackRoute: () => ({ name: 'project' }),
        },
      },
      // --------------------------------
    ],
  },
];
