import projectIssueStatisticsService from '@/api/project-issue-statistics-service';
import i18n from '@/i18n/i18n-config';

export const getDefaultProjectIssueStatisticsFilterParams = () => ({
  environment: 'production',
  employment_status: 'present',
  statuses: ['active', 'planned', 'support'],
});

const state = {
  projectIssueStatistics: [],
  projectIssueStatisticsFilterParams: getDefaultProjectIssueStatisticsFilterParams(),
};

const getters = {
  projectIssueEnvValuesMap() {
    return {
      production: {
        value: 'production',
        text: i18n.t('projects.issue_env_values.production'),
      },
      nonproduction: {
        value: 'nonproduction',
        text: i18n.t('projects.issue_env_values.nonproduction'),
      },
      both: {
        value: 'both',
        text: i18n.t('projects.issue_env_values.both'),
      },
    };
  },
  projectIssueEnvValues(state, getters) {
    return Object.values(getters.projectIssueEnvValuesMap);
  },
};

const mutations = {
  SET_PROJECT_ISSUE_STATISTICS(state, data) {
    state.projectIssueStatistics = data.sort((a, b) => b.total_issues - a.total_issues);
  },

  SET_PROJECT_ISSUE_STATISTICS_FILTER_PARAMS(state, params) {
    state.projectIssueStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchProjectIssueStatistics({ commit }, params) {
    commit('SET_PROJECT_ISSUE_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectIssueStatisticsService.get({ ...params, type: 'projects' });
    commit('SET_PROJECT_ISSUE_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
