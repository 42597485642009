/* eslint-disable no-param-reassign */
import httpClient from '@/api/http-client';

export default {
  storeStrategyGoals({ commit }, payload) {
    commit('storeStrategyGoals', payload);
  },

  getStrategyGoalsReminder({ commit }) {
    httpClient.get('api/strategy-goals-reminder').then((response) => {
      commit('storeStrategyGoalsReminder', response.data);
    });
  },

  createStrategyGoal({ commit }, payload) {
    const now = new Date();
    const oneMonthFromNow = new Date(now.setMonth(now.getMonth() + 1));

    if (!payload) {
      payload = {
        period: 1,
        initial_value: 0,
        target_value: 1,
        current_value: 0,
        acceptable_value: 1,
        starts_at: new Date().toISOString().slice(0, 10),
        ends_at: oneMonthFromNow.toISOString().slice(0, 10),
        status: 'active',
        actions: [],
      };
    }

    commit('createStrategyGoal', payload);
  },

  strategyGoalCreated({ commit, state, dispatch }, payload) {
    state.periodStatusCounts[`${payload.status}_${payload.period}`]++;
    commit('strategyGoalCreated', payload);
    dispatch('getStrategyGoalsReminder');
  },

  strategyGoalUpdated({ commit, state, dispatch }, payload) {
    if (state.defaultGoal.status !== payload.item.status) {
      state.periodStatusCounts[`${state.defaultGoal.status}_${state.defaultGoal.period}`]--;
      state.periodStatusCounts[`${payload.item.status}_${payload.item.period}`]++;
    }
    commit('strategyGoalUpdated', payload);
    dispatch('getStrategyGoalsReminder');
  },

  deleteStrategyGoal({ commit, state, dispatch }, payload) {
    state.periodStatusCounts[`${payload.item.status}_${payload.item.period}`]--;
    commit('deleteStrategyGoal', payload);
    dispatch('getStrategyGoalsReminder');
  },
};
