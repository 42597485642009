<template>
  <v-card class="mb-2">
    <v-card-title class="text-subtitle-2 text-uppercase">
      <a :href="getReactPortalUrl(`project/${sprint.project.id}`)" target="_self" class="link mr-1"
        >{{ sprint.priorityIndex }}. {{ sprint.project.title }}</a
      >
      <span class="text-pre-wrap mr-1">{{ sprint.sprint_name }}</span>
    </v-card-title>

    <ProjectUserStoriesList
      :user-stories="userStories"
      :sprint-id="sprint.id"
      can-change-point-statuses
      @click:row="onActiveUserStoryClick"
    />
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';
import ProjectUserStoriesList from '@/components/ProjectUserStoriesList.vue';
import { getReactPortalUrl } from '@/util/react-portal-integration';

export default {
  name: 'ProjectSprintUserStoriesDashboardCard',

  components: { ProjectUserStoriesList },

  props: {
    sprint: {
      type: Object,
      required: true,
    },
    userStories: {
      type: Array,
      required: true,
    },
  },

  methods: {
    ...mapMutations('projects', ['SET_SELECTED_PROJECT']),
    ...mapMutations('projectUserStories', ['SET_EDITED_PROJECT_USER_STORY']),
    getReactPortalUrl,
    onActiveUserStoryClick(userStory) {
      this.SET_SELECTED_PROJECT(this.sprint.project);
      this.SET_EDITED_PROJECT_USER_STORY(userStory);
      this.$router.push({
        name: 'dashboard_editProjectUserStory',
        params: { projectUserStoryId: userStory.id },
      });
    },
  },
};
</script>

<style scoped></style>
