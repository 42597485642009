<template>
  <v-dialog
    :value="showDialog && value"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    :max-width="maxWidth"
    :scrollable="scrollable"
    :persistent="persistent"
    :eager="eager"
    @click:outside="$emit('click:outside', $event)"
  >
    <slot />
  </v-dialog>
</template>

<script>
// Use on dialogs that are nested inside other dialogs when both all of them are connected to URL
// BaseNestableDialog delays rendering to ensure that dialogs open in the correct order after page refresh
export default {
  name: 'BaseNestableDialog',

  props: {
    value: {
      // in case external control is needed.
      // Since this is usually used in views or with v-if, value prop is not needed in those cases
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: [String, Number],
      default: '700',
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDialog: false,
    };
  },

  mounted() {
    // this ensures that BaseNestable dialog will always be rendered
    // on top of parent dialog when refreshing the page
    this.showDialog = true;
  },
};
</script>

<style scoped></style>
