<template>
  <div>
    <draggable
      :value="userStories"
      :disabled="!canReorder"
      :delay="500"
      :delay-on-touch-only="true"
      :data-sprint-id="sprintId"
      :animation="150"
      :scroll-sensitivity="100"
      :scroll-speed="20"
      group="userStories"
      style="height: 100%; z-index: 30"
      @end="moveProjectUserStory($event)"
    >
      <v-hover v-for="(item, index) in userStories" v-slot="{ hover }" :key="item.id">
        <div
          :data-user-story-id="item.id"
          :data-sprint-id="sprintId"
          :class="{
            'highlight-background': highlightedUserStoryId === item.id,
          }"
          class="pl-2 custom-list-row flex-row align-center clickable flex-wrap flex-sm-nowrap relative"
          @click="onUserStoryClick(item)"
        >
          <div class="d-flex text-body-2 mr-1 break-words">
            <span :class="[{ 'checkbox-wrapper': !isTouchDevice }, 'text-no-wrap']">
              <v-icon
                v-if="showCheckbox(hover)"
                :color="selectedProjectUserStoriesMap[item.id] ? 'primary' : ''"
                @click.stop="TOGGLE_SELECTED_PROJECT_USER_STORY(item.id)"
                >{{
                  selectedProjectUserStoriesMap[item.id] ? 'check_box' : 'check_box_outline_blank'
                }}
              </v-icon>
              <span v-else-if="!isTouchDevice" class="text-no-wrap">{{ index + 1 }}.</span>
            </span>
            <span class="d-inline-flex align-center">{{ item.title }}</span>
          </div>

          <div v-if="$vuetify.breakpoint.xsOnly" class="break" />

          <v-icon v-if="item.info" class="mt-1 mt-sm-0 mr-1" small> description </v-icon>

          <v-icon
            v-if="item.attachments && item.attachments.length"
            class="mt-1 mt-sm-0 mr-1"
            small
          >
            attach_file
          </v-icon>

          <CommentCount :count="item.comments_count" class="mr-1" />

          <TaskCount
            v-if="currentUserPermissionInSelectedProject !== 'client'"
            :total="item.tasks_count"
            :completed="item.completed_tasks_count"
            class="mr-1"
          />

          <ProjectUserStoryUrgencyIndicator :project-user-story="item" />

          <ProjectUserStoryClientApprovalStatusIndicator :project-user-story="item" />

          <v-spacer />

          <ProjectUserStoryTestStatusIndicator
            v-if="currentUserPermissionInSelectedProject !== 'client'"
            :test-status="item.test_status"
            :tested-by="item.tested_by"
            icon-class="mr-1"
          />

          <ProjectUserStoryDeployStatusIndicator
            v-if="currentUserPermissionInSelectedProject !== 'client'"
            :deploy-statuses="item.deploy_statuses"
            icon-class="mr-1"
          />

          <div
            v-if="currentUserPermissionInSelectedProject === 'client'"
            class="ml-auto mt-1 mt-sm-0"
          >
            <ProjectUserStoryPointsClientStatus :user-story="item" />
          </div>

          <ProjectUserStoryPoints
            v-else
            :user-story="item"
            :can-change-statuses="canChangePointStatuses"
            :sprint-id="sprintId"
            class="text-right ml-auto mt-1 mt-sm-0"
          />
        </div>
      </v-hover>
    </draggable>

    <div v-if="!userStories.length" class="text-center pa-3">
      <span v-if="sprintId">{{ $t('projects.general.no_assigned_stories') }}.</span>
      <span v-else>{{ $t('projects.general.no_unassigned_stories') }}.</span>
    </div>
  </div>
</template>

<script>
import CommentCount from '@/components/CommentCount.vue';
import TaskCount from '@/components/TaskCount.vue';
import ProjectUserStoryPoints from '@/components/ProjectUserStoryPoints.vue';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ProjectUserStoryDeployStatusIndicator from '@/components/indicators/ProjectUserStoryDeployStatusIndicator.vue';
import ProjectUserStoryTestStatusIndicator from '@/components/indicators/ProjectUserStoryTestStatusIndicator.vue';
import ProjectUserStoryPointsClientStatus from '@/components/ProjectUserStoryPointsClientStatus.vue';
import ProjectUserStoryUrgencyIndicator from '@/components/indicators/ProjectUserStoryUrgencyIndicator.vue';
import ProjectUserStoryClientApprovalStatusIndicator from '@/components/indicators/ProjectUserStoryClientApprovalStatusIndicator.vue';

export default {
  name: 'ProjectUserStoriesList',

  components: {
    ProjectUserStoryClientApprovalStatusIndicator,
    ProjectUserStoryUrgencyIndicator,
    ProjectUserStoryPointsClientStatus,
    ProjectUserStoryTestStatusIndicator,
    ProjectUserStoryDeployStatusIndicator,
    ProjectUserStoryPoints,
    TaskCount,
    CommentCount,
    draggable,
  },

  props: {
    userStories: {
      type: Array,
      default: () => [],
    },
    sprintId: {
      type: Number,
      default: null,
    },
    canReorder: {
      type: Boolean,
      default: false,
    },
    canChangePointStatuses: {
      type: Boolean,
      default: false,
    },
    showCheckboxes: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('settings', ['isTouchDevice']),
    ...mapState('projectUserStories', ['selectedProjectUserStoriesMap', 'highlightedUserStoryId']),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),

    selectedUserStoriesCount() {
      return Object.keys(this.selectedProjectUserStoriesMap).length;
    },

    enableProjectUserStoryCheckboxes() {
      return this.showCheckboxes && this.currentUserPermissionInSelectedProject !== 'client';
    },
  },

  methods: {
    ...mapMutations('projectUserStories', ['TOGGLE_SELECTED_PROJECT_USER_STORY']),
    ...mapActions('projectUserStories', ['moveProjectUserStory']),

    onUserStoryClick(item) {
      this.$emit('click:row', item);
    },

    showCheckbox(isHovered) {
      return (
        this.enableProjectUserStoryCheckboxes &&
        (isHovered || this.selectedUserStoriesCount > 0 || this.isTouchDevice)
      );
    },
  },
};
</script>

<style scoped>
.checkbox-wrapper {
  min-width: 24px;
  min-height: 24px;
  margin-right: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>
