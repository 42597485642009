import Vue from 'vue';
import todosService from '@/api/todos-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';

export const getDefaultTodoFormItem = () => ({});

export const getDefaultTodoFilterParams = () => ({});

const state = {
  todos: [],
  todoPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  newTodo: getDefaultTodoFormItem(),
  editedTodo: {},
  todoValidationErrors: {},
  todoFilterParams: getDefaultTodoFilterParams(),
};

const getters = {};

const mutations = {
  SET_TODOS(state, { data, current_page, per_page, total }) {
    state.todos = data;
    state.todoPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_TODO_FILTER_PARAMS(state, params) {
    state.todoFilterParams = params;
  },

  SET_EDITED_TODO(state, todo) {
    state.todoValidationErrors = {};
    state.editedTodo = JSON.parse(JSON.stringify(todo));
  },

  STORE_TODO(state, todo) {
    state.todos.push(todo);
    state.todoPagination.total += 1;
    state.todoValidationErrors = {};
    state.newTodo = getDefaultTodoFormItem();
  },

  UPDATE_TODO(state, todo) {
    state.todos = updateArrayItem(state.todos, todo);
  },

  DELETE_TODO(state, todo) {
    state.todos = removeArrayItem(state.todos, todo);
    state.todoPagination.total -= 1;
  },

  SET_TODO_VALIDATION_ERRORS(state, todoValidationErrors) {
    state.todoValidationErrors = todoValidationErrors;
  },

  CLEAR_TODO_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.todoValidationErrors, field);
  },

  SORT_TODOS(state, todos) {
    state.todos = todos;
  },
};

const actions = {
  fetchTodos({ commit }, params) {
    commit('SET_TODO_FILTER_PARAMS', params);
    return todosService.getPage(params).then((res) => {
      commit('SET_TODOS', res.data);
      return res.data;
    });
  },

  storeTodo({ commit }, todo) {
    return todosService
      .create(todo)
      .then((res) => {
        commit('STORE_TODO', res.data);
        openSnackbar(i18n.t('general.entry_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_TODO_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editTodo({ state, commit }, todoId) {
    const todo = state.todos?.find((c) => c.id === todoId);
    if (todo) {
      commit('SET_EDITED_TODO', todo);
      return Promise.resolve(todo);
    }
    return todosService.getById(todoId).then((res) => {
      commit('SET_EDITED_TODO', res.data);
      return res.data;
    });
  },

  updateTodo({ commit }, todo) {
    return todosService
      .update(todo)
      .then((res) => {
        commit('UPDATE_TODO', {
          ...todo,
          ...res.data,
        });
        openSnackbar(i18n.t('general.entry_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_TODO_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async deleteTodo({ commit }, todo) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    });
    if (!confirmed) {
      return false;
    }
    try {
      await todosService.delete(todo);
      commit('DELETE_TODO', todo);
      openSnackbar(i18n.t('general.entry_deleted'));
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  sortTodos({ commit }, sort) {
    return todosService.sort(sort).then((res) => {
      commit('SORT_TODOS', res.data);
      return res.data;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
