import projectIssueStatisticsService from '@/api/project-issue-statistics-service';
import i18n from '@/i18n/i18n-config';

export const getDefaultEmployeeIssueStatisticsFilterParams = () => ({
  environment: 'production',
  employment_status: 'present',
  statuses: ['active', 'planned', 'support'],
});

const state = {
  employeeIssueStatistics: [],
  employeeIssueStatisticsFilterParams: getDefaultEmployeeIssueStatisticsFilterParams(),
};

const getters = {
  employeeEmploymentStatusValuesMap() {
    return {
      present: {
        value: 'present',
        text: i18n.t('projects.employment_status_values.present'),
      },
      passed: {
        value: 'passed',
        text: i18n.t('projects.employment_status_values.passed'),
      },
      all: {
        value: 'all',
        text: i18n.t('projects.employment_status_values.all'),
      },
    };
  },
  employeeEmploymentStatusValues(state, getters) {
    return Object.values(getters.employeeEmploymentStatusValuesMap);
  },
};

const mutations = {
  SET_EMPLOYEE_ISSUE_STATISTICS(state, data) {
    state.employeeIssueStatistics = data.sort((a, b) => b.total_issues - a.total_issues);
  },

  SET_EMPLOYEE_ISSUE_STATISTICS_FILTER_PARAMS(state, params) {
    state.employeeIssueStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchEmployeeIssueStatistics({ commit }, params) {
    commit('SET_EMPLOYEE_ISSUE_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectIssueStatisticsService.get({ ...params, type: 'users' });
    commit('SET_EMPLOYEE_ISSUE_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
