/* eslint-disable max-len,no-param-reassign */
import moment from 'moment';

export default {
  storeStrategyGoals(state, payload) {
    state.strategyGoals[payload.period] = payload.items;
  },

  storeTimelineGoals(state, payload) {
    state.timelineGoals = payload.goals;
    state.timelineYear = payload.year;
  },

  storeStrategyGoalsReminder(state, payload) {
    state.strategyGoalsReminder = payload;
    state.strategyGoalsReminder.total_missing = 0;
    state.strategyGoalsReminder.missing_goals.forEach((period) => {
      state.strategyGoalsReminder.total_missing += period.missing_goal_count;
    });
    state.strategyGoalsReminder.total_not_planned = 0;
    state.strategyGoalsReminder.not_planned_goals.forEach((period) => {
      state.strategyGoalsReminder.total_not_planned += period.missing_goal_count;
    });
  },

  changeGoalStatus(state, status) {
    state.selectedGoalStatus = status;
  },

  createStrategyGoal(state, payload) {
    state.editedGoal = payload;
    state.editedGoalIndex = -1;
    state.strategyGoalDialog = true;
  },

  strategyGoalCreated(state, goal) {
    state.strategyGoals['0'].push(JSON.parse(JSON.stringify(goal)));
    if (state.selectedGoalStatus === goal.status) {
      state.strategyGoals[goal.period].push(JSON.parse(JSON.stringify(goal)));
    }
    const startsAtYear = moment(goal.starts_at).format('YYYY');
    const endsAtYear = moment(goal.ends_at).format('YYYY');
    if (startsAtYear === state.timelineYear || endsAtYear === state.timelineYear) {
      state.timelineGoals.push(goal);
    }
    state.strategyGoalDialog = false;
  },

  editStrategyGoal(state, payload) {
    payload.item.initial_value = +payload.item.initial_value;
    payload.item.current_value = +payload.item.current_value;
    payload.item.target_value = +payload.item.target_value;
    payload.item.acceptable_value = +payload.item.acceptable_value;
    state.editedGoal = JSON.parse(JSON.stringify(payload.item));
    state.defaultGoal = JSON.parse(JSON.stringify(payload.item));
    state.editedGoalPeriod = payload.period;
    state.editedGoalIndex = payload.index;
    state.strategyGoalDialog = true;
  },

  strategyGoalUpdated(state, payload) {
    const newGoalCount = state.strategyGoals['0'].length;
    for (let i = 0; i < newGoalCount; i++) {
      if (state.strategyGoals['0'][i].id === payload.item.id) {
        state.strategyGoals['0'].splice(i, 1, payload.item);
        const count = state.strategyGoals[payload.item.period].length;
        for (let j = 0; j < count; j++) {
          if (state.strategyGoals[payload.item.period][j].id === payload.item.id) {
            if (payload.item.status !== state.defaultGoal.status) {
              state.strategyGoals[state.defaultGoal.period].splice(state.editedGoalIndex, 1);
            } else {
              state.strategyGoals[payload.item.period].splice(j, 1, JSON.parse(JSON.stringify(payload.item)));
            }
            if (!payload.keepDialogOpen) {
              state.strategyGoalDialog = false;
            }
            return;
          }
        }
      }
    }
    if (payload.item.status !== state.defaultGoal.status) {
      state.strategyGoals[state.defaultGoal.period].splice(state.editedGoalIndex, 1);
    } else if (payload.item.period !== state.defaultGoal.period) {
      state.strategyGoals[state.defaultGoal.period].splice(state.editedGoalIndex, 1);
      state.strategyGoals[payload.item.period].push(payload.item);
    } else {
      state.strategyGoals[payload.item.period].splice(state.editedGoalIndex, 1, payload.item);
    }
    for (let i = 0; i < state.timelineGoals.length; i++) {
      if (state.timelineGoals[i].id === payload.item.id) {
        const startsAtYear = moment(payload.item.starts_at).format('YYYY');
        const endsAtYear = moment(payload.item.ends_at).format('YYYY');
        if (startsAtYear === state.timelineYear || endsAtYear === state.timelineYear) {
          state.timelineGoals.splice(i, 1, payload.item);
        } else {
          state.timelineGoals.splice(i, 1);
        }
      }
    }
    if (!payload.keepDialogOpen) {
      state.strategyGoalDialog = false;
    }
  },

  /**
   * removes a goal from both places where it might exist:
   * recently created goals array: state.strategyGoals['0'] and
   * the period which the goal was in array: state.strategyGoals[payload.item.period]
   * @param state
   * @param payload (item: StrategyGoal)
   */
  deleteStrategyGoal(state, payload) {
    let index = state.strategyGoals['0'].findIndex(goal => payload.item.id === goal.id);
    if (index > -1) {
      state.strategyGoals['0'].splice(index, 1);
    }
    index = state.strategyGoals[payload.item.period].findIndex(goal => payload.item.id === goal.id);
    if (index > -1) {
      state.strategyGoals[payload.item.period].splice(index, 1);
    }
  },
};
