<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ClientAgreementForm
      :client-agreement="newClientAgreement"
      :errors="clientAgreementValidationErrors"
      :disabled="$store.getters.loading['post:api/documents']"
      @clear:errors="CLEAR_CLIENT_AGREEMENT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ClientAgreementForm from '@/components/forms/ClientAgreementForm.vue';
import { returnFromChildView } from '@/util/routing';

export default {
  name: 'CreateClientAgreement',

  components: { ClientAgreementForm },

  computed: {
    ...mapState('clientAgreements', [
      'newClientAgreement',
      'clientAgreementValidationErrors',
      'clientAgreementFilterParams',
    ]),
  },

  created() {
    this.SET_CLIENT_AGREEMENT_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('clientAgreements', ['storeClientAgreement']),
    ...mapMutations('clientAgreements', [
      'SET_CLIENT_AGREEMENT_VALIDATION_ERRORS',
      'CLEAR_CLIENT_AGREEMENT_VALIDATION_ERRORS',
    ]),

    onSave(clientAgreement) {
      this.storeClientAgreement(clientAgreement).then(() => {
        this.goBack();
      });
    },

    goBack() {
      returnFromChildView(this.$route, 'uploading-document', {
        name: 'clientAgreements',
        query: this.clientAgreementFilterParams,
      });
    },
  },
};
</script>
