import httpClient from '@/api/http-client';
import clone from 'just-clone';

const endpoint = 'api/users';

function getUserBackendStructure(user) {
  const item = clone(user);
  item.client_ids = user.clients.map((c) => c.id);
  delete item.clients;
  return item;
}

const userService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getFullNameById: (id) => httpClient.get(`api/user-full-name/${id}`),

  getCurrent: () => httpClient.get('api/user'),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  create: (user) => httpClient.post(`api/client-portal-user`, getUserBackendStructure(user)),

  update: (user) =>
    httpClient.put(`api/client-portal-user/${user.id}`, getUserBackendStructure(user)),

  delete: (user) => httpClient.delete(`${endpoint}/${user.id}`),

  toggleStatus: (user) => httpClient.put(`${endpoint}/${user.id}/toggle-suspend`),

  resetPassword: (user) => httpClient.post(`${endpoint}/${user.id}/reset-password`, user),

  changeCurrentUserPassword: (payload) => httpClient.put('api/current-user/password', payload),

  getAvailablePermissions: () => httpClient.get(`${endpoint}/available-options/permissions`),

  sendNewPasswordViaEmail: (user) => httpClient.post(`${endpoint}/${user.id}/send-password`),

  getCurrentUserAvailableVacationDays: () =>
    httpClient.get('api/current-user-available-vacation-days'),

  updateRole: (user, newRole) =>
    httpClient.put(`api/users/${user.id}/change-role`, { role: newRole }),

  togglePermission: (user, permission) =>
    httpClient.put(`api/users/${user.id}/toggle-permission`, { permission }),

  getHolidayApprovers: () =>
    httpClient.get(
      `${endpoint}/filter?roles[]=admin&roles[]=employee&permissions[]=employees.approve_absence`
    ),

  getResponsibleColleaguesForHoliday: (dates) => {
    let url = `${endpoint}/filter?roles[]=admin&roles[]=employee`;

    if (dates.available_from) {
      url += `&available_from=${dates.available_from}`;
    }
    if (dates.available_to) {
      url += `&available_to=${dates.available_to}`;
    }

    return httpClient.get(url);
  },
};

export default userService;
