<template>
  <v-progress-circular v-if="loading && !items.length" class="ma-4" color="primary" indeterminate />
  <v-card v-else :class="{ 'loading-overlay': loading }" flat>
    <div
      v-for="item in items"
      :key="item.id"
      class="custom-list-row justify-space-between text-body-2"
      @click="$emit('click:row', item)"
    >
      <div>
        <div v-if="showProjectLink && item.project" class="d-inline-flex" @click.stop>
          <a
            :href="getReactPortalUrl(`project/${item.project.id}`)"
            target="_self"
            class="link"
            @click.stop
            >{{ item.project.title.toUpperCase() }}</a
          >
        </div>
        <div :class="{ completed: item.status === 'closed' }" class="mr-1 text-break">
          {{ item.title }}
          <template v-if="item.external_issues_count > 1">
            ({{ item.external_issues_count }})
          </template>
        </div>
        <div class="d-flex align-center">
          <span class="mr-1 grey--text">
            {{ $timeFromDate(item.created_at) }}
          </span>
          <v-icon
            v-if="(!$isClient() && item.priority === 'important') || item.priority === 'critical'"
            :color="item.priority === 'important' ? 'warning' : 'error'"
            class="mr-1"
            small
            >offline_bolt</v-icon
          >
          <v-tooltip
            v-if="
              item.planned_implementation_at &&
              item.planned_implementation_at < today &&
              !['closed', 'rejected', 'deployed_to_production'].includes(item.status)
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="error" class="mr-1" small>error</v-icon>
            </template>
            <span>
              {{ $t('company_management.general.late') }}
              {{ $t('projects.labels.planned_implementation') }}:
              {{ item.planned_implementation_at }}
            </span>
          </v-tooltip>
          <v-icon v-if="item.description" class="mr-1" small>description</v-icon>
          <v-icon v-if="item.attachments && item.attachments.length" class="mr-1" small
            >attach_file</v-icon
          >
          <CommentCount :count="item.comments_count" class="mr-1" />
        </div>
      </div>

      <div class="d-flex align-center">
        <ProjectIssueIsResolvedIndicator
          :issue="item"
          @updated="$emit('updated:is-resolved', $event)"
        />

        <div class="mr-1">
          <BaseChipSelect
            :value="item.status"
            :disabled="$isClient() || !canChangeStatus"
            :items="projectIssueStatuses"
            max-height="400"
            @change="$emit('update:status', $event, item)"
          />
        </div>

        <UserAvatar
          v-if="!$isClient()"
          :full-name="getIssueAuthorFullName(item)"
          :tooltip-prefix="`${$t('general.created_by')}: `"
          class="mr-1"
        />

        <UserAvatar
          v-if="!$isClient()"
          :full-name="getIssueAssigneeFullName(item)"
          :tooltip="
            getIssueAssigneeFullName(item)
              ? `${$t('projects.labels.assigned_employee')}: ${getIssueAssigneeFullName(item)}`
              : $t('projects.general.no_assigned_employee')
          "
        />
      </div>
    </div>

    <div v-if="!items.length" class="text-center pa-3">
      {{ $t('general.no_data') }}
    </div>

    <template v-if="pagination">
      <v-pagination
        v-if="
          pagination.current_page < Math.ceil(pagination.total / pagination.per_page) ||
          pagination.current_page > 1
        "
        :value="pagination.current_page"
        :length="Math.ceil(pagination.total / pagination.per_page)"
        class="my-2"
        circle
        @input="$emit('update:page', $event)"
      />
    </template>
  </v-card>
</template>

<script>
import CommentCount from './CommentCount.vue';
import BaseChipSelect from '@/components/base/BaseChipSelect.vue';
import { mapGetters } from 'vuex';
import UserAvatar from '@/components/UserAvatar.vue';
import { format } from 'date-fns';
import ProjectIssueIsResolvedIndicator from '@/components/indicators/ProjectIssueIsResolvedIndicator.vue';
import { getReactPortalUrl } from '@/util/react-portal-integration';

export default {
  name: 'ProjectIssueList',

  components: {
    ProjectIssueIsResolvedIndicator,
    UserAvatar,
    BaseChipSelect,
    CommentCount,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canChangeStatus: {
      type: Boolean,
      default: false,
    },
    showProjectLink: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      today: format(new Date(), 'yyyy-MM-dd'),
    };
  },

  computed: {
    ...mapGetters('projectIssues', ['projectIssueStatuses']),
  },

  methods: {
    getReactPortalUrl,
    getIssueAuthorFullName(issue) {
      return issue.author?.personal_data?.full_name || this.$t('general.system');
    },

    getIssueAssigneeFullName(issue) {
      return issue.assignee?.personal_data?.full_name;
    },
  },
};
</script>

<style scoped></style>
