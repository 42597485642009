import projectIssueStatisticsService from '@/api/project-issue-statistics-service';
import { format } from 'date-fns';
import i18n from '../../i18n/i18n-config.js';

export const getDefaultIssueStatusesStatisticsFilterParams = () => ({
  year: format(new Date(), 'yyyy'),
  environment: 'production',
  employment_status: 'present',
  statuses: ['active', 'planned', 'support'],
});

const state = {
  issueStatusesStatistics: [],
  // issueStatusesStatisticsTotals: {},
  issueStatusesStatisticsFilterParams: getDefaultIssueStatusesStatisticsFilterParams(),
};

const getters = {
  issueStatusesMap() {
    return {
      seconds_spent_in_new_average: {
        value: 'seconds_spent_in_new_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_new_average'),
      },
      seconds_spent_in_seen_average: {
        value: 'seconds_spent_in_seen_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_seen_average'),
      },
      seconds_spent_in_in_progress_average: {
        value: 'seconds_spent_in_in_progress_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_in_progress_average'),
      },
      seconds_spent_in_ready_for_test_average: {
        value: 'seconds_spent_in_ready_for_test_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_ready_for_test_average'),
      },
      seconds_spent_in_deployed_to_staging_average: {
        value: 'seconds_spent_in_deployed_to_staging_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_deployed_to_staging_average'),
      },
      seconds_spent_in_deployed_to_production_average: {
        value: 'seconds_spent_in_deployed_to_production_average',
        text: i18n.t(
          'projects.issue_statuses_texts.seconds_spent_in_deployed_to_production_average'
        ),
      },
      seconds_spent_in_postponed_average: {
        value: 'seconds_spent_in_postponed_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_postponed_average'),
      },
      seconds_spent_in_closed_average: {
        value: 'seconds_spent_in_closed_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_closed_average'),
      },
      seconds_spent_in_rejected_average: {
        value: 'seconds_spent_in_rejected_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_rejected_average'),
      },
      seconds_spent_in_needs_info_average: {
        value: 'seconds_spent_in_needs_info_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_in_needs_info_average'),
      },
      seconds_spent_to_resolve_average: {
        value: 'seconds_spent_to_resolve_average',
        text: i18n.t('projects.issue_statuses_texts.seconds_spent_to_resolve_average'),
      },
    };
  },
};

const mutations = {
  SET_ISSUE_STATUSES_STATISTICS(state, data) {
    // if (!params.group_by_project) {
    //     // eslint-disable-next-line prefer-destructuring
    //     state.issueStatusesStatisticsTotals = data.slice(-1)[0];
    //     state.issueStatusesStatistics = data.slice(0, -1);
    // } else {
    //     state.issueStatusesStatisticsTotals = null;
    state.issueStatusesStatistics = data;
    // }
  },

  SET_ISSUE_STATUSES_STATISTICS_FILTER_PARAMS(state, params) {
    state.issueStatusesStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchIssueStatusesStatistics({ commit }, params) {
    commit('SET_ISSUE_STATUSES_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectIssueStatisticsService.getIssueStatusesStatistics(params);
    commit('SET_ISSUE_STATUSES_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
