<template>
  <v-tooltip
    v-if="deployStatuses.length"
    :disabled="isTooltipDisabled"
    :key="tooltipText"
    content-class="tooltip-in-dialog"
    bottom
  >
    <template #activator="{ on }">
      <v-icon
        v-on="on"
        :color="statusDetails.color"
        :class="iconClass"
        small
        @click.stop
        @mouseenter="fetchDeployedByDetails"
        >{{ statusDetails.icon }}</v-icon
      >
    </template>
    <div class="text-pre-wrap">{{ tooltipText }}</div>
  </v-tooltip>
</template>

<script>
import userService from '@/api/user-service';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ProjectUserStoryDeployStatusIndicator',

  props: {
    deployStatuses: {
      type: Array,
      default: () => [],
    },
    iconClass: {
      type: [String, Array, Object],
      default: '',
    },
  },

  data() {
    return {
      loadingDeployedBy: false,
    };
  },

  computed: {
    ...mapState('users', ['userFullNamesMap']),
    ...mapGetters('projectUserStories', ['projectUserStoryDeployStatuses']),

    statusDetails() {
      // show production if everything's in production
      // show staging if at least one is in staging
      // otherwise - not deployed
      let deployStatus = this.projectUserStoryDeployStatuses.slice(-1).pop();

      for (let i = 0; i < this.deployStatuses.length; i++) {
        if (this.deployStatuses[i].status === 'staging') {
          deployStatus = this.projectUserStoryDeployStatuses.find((s) => s.value === 'staging');
        }
      }

      return deployStatus;
    },

    usersToFetch() {
      const usersToFetch = new Set();
      for (let i = 0; i < this.deployStatuses.length; i++) {
        const { deployed_by } = this.deployStatuses[i];
        if (!this.userFullNamesMap[deployed_by] && deployed_by) {
          usersToFetch.add(deployed_by);
        }
      }
      return Array.from(usersToFetch);
    },

    isTooltipDisabled() {
      return this.loadingDeployedBy || this.usersToFetch.length > 0;
    },

    tooltipText() {
      if (this.loadingDeployedBy || this.usersToFetch.length) {
        return '';
      }

      let parts = [];
      for (let i = 0; i < this.deployStatuses.length; i++) {
        const { status, git_repo_url, deployed_by } = this.deployStatuses[i];
        parts.push(
          this.$t('projects.deployed_what_where_who')
            .replace('{0}', git_repo_url || '')
            .replace('{1}', status)
            .replace('{2}', this.userFullNamesMap[deployed_by] || '-')
        );
      }
      return parts.join('\n');
    },
  },

  methods: {
    ...mapMutations('users', ['SET_USER_FULL_NAME']),

    async fetchDeployedByDetails() {
      if (this.loadingDeployedBy || this.usersToFetch.length === 0) {
        return;
      }

      this.loadingDeployedBy = true;
      try {
        const requests = [];
        this.usersToFetch.forEach((id) => {
          requests.push(
            userService.getFullNameById(id).then(({ data }) => {
              this.SET_USER_FULL_NAME({ userId: id, fullName: data.full_name });
            })
          );
        });

        await Promise.allSettled(requests);
      } catch (e) {
        // handled by generic error handler
      }
      this.loadingDeployedBy = false;
    },
  },
};
</script>

<style scoped></style>
