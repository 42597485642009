<template>
  <v-tooltip
    v-if="
      projectUserStory.is_urgent &&
      currentUserPermissionInSelectedProject !== 'client' &&
      projectUserStory.status !== 'done'
    "
    content-class="tooltip-in-dialog"
    bottom
  >
    <template #activator="{ on }">
      <v-icon
        v-on="on"
        :color="isUserStoryOverdue(projectUserStory) ? 'error' : 'warning'"
        class="mr-1"
        small
        >offline_bolt</v-icon
      >
    </template>
    <div>
      {{ $t(`projects.labels.is_${isUserStoryOverdue(projectUserStory) ? 'overdue' : 'urgent'}`) }}!
      <template v-if="projectUserStory.due_date">
        {{ $t('projects.labels.deadline') }}: {{ projectUserStory.due_date }}
      </template>
    </div>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectUserStoryUrgencyIndicator',

  props: {
    projectUserStory: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loadingDeployedBy: false,
    };
  },

  computed: {
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
  },

  methods: {
    isUserStoryOverdue(item) {
      return item.is_urgent && item.due_date && new Date() > new Date(item.due_date);
    },
  },
};
</script>

<style scoped></style>
