<template>
  <v-slide-y-transition mode="out-in">
    <v-card v-if="items.length > 0">
      <v-subheader>
        <v-icon class="mr-2">event</v-icon>
        {{ $t('general.upcoming_events') }}
      </v-subheader>

      <v-data-table
        :headers="headers"
        :items="items"
        :mobile-breakpoint="0"
        :item-class="() => 'clickable'"
        disable-pagination
        disable-sort
        hide-default-footer
        @click:row="(item) => $router.push({ name: 'calendar', params: { date: item.start_date } })"
      >
      </v-data-table>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
export default {
  name: 'UpcomingCalendarEvents',

  props: {
    items: Array,
  },

  computed: {
    headers() {
      return [
        { text: this.$t('general.event'), value: 'title' },
        { text: this.$t('general.date_and_time.starts_at'), value: 'start_date' },
      ];
    },
  },
};
</script>
