<template>
  <v-card>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row dense>
            <v-col class="form-group-header mt-0" cols="12">
              <h3 class="text-h6">
                {{ $t('password_manager.general.password_sharing') }}
              </h3>
            </v-col>

            <v-col cols="12">
              <app-autocomplete
                :searchRoute="userSearchRoute"
                :item="selectedUser"
                :error-messages="backendErrors.shared_to"
                :labelText="$t('general.choose_user')"
                textField="personal_data.full_name"
                clearable
                @itemSelected="setUser"
              ></app-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click.native="close">
        {{ $t('general.controls.cancel') }}
      </v-btn>
      <v-btn :disabled="requestPending" color="primary" text @click.native="save">
        {{ $t('general.controls.share') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Autocomplete from '../form-fields/Autocomplete.vue';
import deprecatedFormMixin from '../../mixins/deprecated/form-mixin';

export default {
  name: 'PasswordShareForm',

  components: {
    appAutocomplete: Autocomplete,
  },

  mixins: [deprecatedFormMixin],

  data() {
    return {
      userSearchRoute: 'api/users/find/',
      selectedUser: null,
      backendErrors: {
        shared_to: [],
      },
    };
  },

  methods: {
    prepareForm() {
      this.selectedUser = null;
    },

    save() {
      const requestBody = {
        shared_to: this.selectedUser ? this.selectedUser.id : null,
        unencrypted_entity_details: this.item.details,
      };
      this.requestPending = true;
      this.$http
        .post(`api/www-resources/${this.item.id}/share`, requestBody)
        .then(() => {
          this.$emit('passwordShared', this.selectedUser);
        })
        .catch((error) => {
          this.handleBackendErrors(error);
          this.requestPending = false;
        });
    },

    setUser(user) {
      this.selectedUser = user;
    },
  },
};
</script>
