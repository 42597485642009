import projectIssueStatisticsService from '@/api/project-issue-statistics-service';
import { format } from 'date-fns';

export const getDefaultIssueCreatedByStatisticsFilterParams = () => ({
  year: format(new Date(), 'yyyy'),
  environment: 'production',
  employment_status: 'present',
  statuses: ['active', 'planned', 'support'],
});

const state = {
  issueCreatedByStatistics: [],
  issueCreatedByStatisticsTotals: {},
  issueCreatedByStatisticsFilterParams: getDefaultIssueCreatedByStatisticsFilterParams(),
};

const getters = {};

const mutations = {
  SET_ISSUE_CREATED_BY_STATISTICS(state, { data, params }) {
    if (!params.group_by_project) {
      // eslint-disable-next-line prefer-destructuring
      state.issueCreatedByStatisticsTotals = data.slice(-1)[0];
      state.issueCreatedByStatistics = data.slice(0, -1);
    } else {
      state.issueCreatedByStatisticsTotals = null;
      state.issueCreatedByStatistics = data;
    }
  },

  SET_ISSUE_CREATED_BY_STATISTICS_FILTER_PARAMS(state, params) {
    state.issueCreatedByStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchIssueCreatedByStatistics({ commit }, params) {
    commit('SET_ISSUE_CREATED_BY_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectIssueStatisticsService.getCreatedByStatistics(params);
    commit('SET_ISSUE_CREATED_BY_STATISTICS', { data, params });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
