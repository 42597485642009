import httpClient from '@/api/http-client';

export default {
  getStrategyGoalCategories({ commit }) {
    httpClient.get('/api/strategy-goal-categories').then((response) => {
      commit('storeStrategyGoalCategories', response.data);
    });
  },

  storeStrategyGoalCategories({ commit }, strategyGoalCategories) {
    commit('storeStrategyGoalCategories', strategyGoalCategories);
  },

  storeStrategyGoalCategory({ commit }, strategyGoalCategory) {
    commit('storeStrategyGoalCategory', strategyGoalCategory);
  },

  updateStrategyGoalCategory({ commit }, payload) {
    commit('updateStrategyGoalCategory', payload);
  },

  deleteStrategyGoalCategory({ commit }, index) {
    commit('deleteStrategyGoalCategory', index);
  },
};
