<template>
  <v-slide-y-transition mode="out-in">
    <v-card v-if="!isWikiLoading" class="mini-wiki">
      <template v-if="!editing">
        <v-subheader>
          <v-icon class="mr-2">chat</v-icon>
          {{ $t('home_page.mini_wiki.header') }}
        </v-subheader>
        <v-card-text
          class="pa-0 editable"
          style="min-height: 200px; color: unset"
          @click="editMiniWiki"
        >
          <div v-html="miniWiki" class="ql-editor" />
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text class="pa-0" style="color: unset">
          <div>
            <quill-editor
              v-model="editedMiniWiki"
              id="quill-div"
              ref="myQuillEditor"
              :class="{ inverted: $store.state.settings.darkTheme }"
              :options="editorOptions"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click.native="cancelEditing">
            {{ $t('general.controls.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="requestPending"
            :loading="requestPending"
            text
            @click.native="updateMiniWiki"
          >
            {{ $t('general.controls.save') }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import eventBus, { OPEN_SNACKBAR, openSnackbar } from '@/util/event-bus';

export default {
  name: 'MiniWiki',

  data() {
    return {
      wikiRoute: 'api/mini-wiki',
      miniWiki: '',
      editedMiniWiki: '',
      requestPending: false,
      isWikiLoading: true,
      editing: false,
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['image', 'link'],
          ],
        },
        placeholder: '',
        theme: 'snow',
      },
    };
  },

  created() {
    this.getMiniWiki();
  },

  methods: {
    updateMiniWiki() {
      this.requestPending = true;
      this.$http
        .post(this.wikiRoute, { info: this.editedMiniWiki })
        .then((response) => {
          this.miniWiki = response.data.info;
          openSnackbar(this.$t('home_page.mini_wiki.mini_wiki_updated'));
        })
        .finally(() => {
          this.requestPending = false;
          this.editing = false;
        });
    },

    getMiniWiki() {
      this.$http.get(this.wikiRoute).then((response) => {
        this.miniWiki = response.data.info;
        if (!this.miniWiki) {
          this.miniWiki = this.$t('home_page.mini_wiki.header');
        }
        this.isWikiLoading = false;
      });
    },

    editMiniWiki() {
      this.editedMiniWiki = this.miniWiki;
      this.editing = true;
    },

    cancelEditing() {
      this.editing = false;
    },
  },
};
</script>

<style scoped>
.ql-snow .ql-stroke {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.inverted {
  filter: invert(1);
  color: black;
}
</style>
