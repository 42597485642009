<template>
  <div>
    <v-tooltip v-if="!$isClient()" max-width="30em" bottom>
      <template #activator="{ on }">
        <div v-on="on" class="d-inline-flex">
          <v-switch
            :input-value="showAlternateDashboard"
            :label="$t('profile.labels.show_alternate_dashboard')"
            class="ma-0 pa-0"
            hide-details
            @change="onChange"
          />
        </div>
      </template>
      <div class="text-pre-wrap">{{ $t('general.alternate_dashboard_description') }}</div>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'NewDashboardSwitch',

  computed: {
    ...mapState('settings', ['showAlternateDashboard']),
  },

  methods: {
    ...mapActions('settings', ['updateSetting']),

    onChange(value) {
      this.updateSetting({
        key: 'showAlternateDashboard',
        value,
      });
    },
  },
};
</script>

<style scoped></style>
