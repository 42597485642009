import httpClient from '@/api/http-client';

const projectDocumentationService = {
  getAll: (projectId, params = {}) =>
    httpClient.get(`api/projects/${projectId}/documentations`, { params }),

  getById: (projectId, projectDocumentationId) =>
    httpClient.get(`api/projects/${projectId}/documentations/${projectDocumentationId}`),

  create: (projectId, projectDocumentation) =>
    httpClient.post(`api/projects/${projectId}/documentations`, projectDocumentation),

  reorder: (projectId, payload) =>
    httpClient.post(`api/projects/${projectId}/documentations/reorder`, payload),

  update: (projectId, projectDocumentation) =>
    httpClient.put(
      `api/projects/${projectId}/documentations/${projectDocumentation.id}`,
      projectDocumentation
    ),

  delete: (projectId, projectDocumentation) =>
    httpClient.delete(`api/projects/${projectId}/documentations/${projectDocumentation.id}`),
};

export default projectDocumentationService;
