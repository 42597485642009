import Vue from 'vue';
import projectIssueService from '@/api/project-issue-service';
import i18n from '@/i18n/i18n-config';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { collectMultiSelectErrors, mapErrorsToInputs } from '@/util/forms';
import { getSanitizedFilterParams } from '@/util/filter-params';
import { getLocalStorageItem } from '@/util/local-storage';

function getDefaultProjectIssueFormItem() {
  return {
    status: 'new',
    priority: 'normal',
    category: null,
    is_solved: false,
    is_resolved: false,
    is_in_production: true,
  };
}

export function getDefaultProjectIssueFilterParams() {
  return {};
}

export function getCachedProjectIssueFilterParams() {
  const params = getLocalStorageItem('projectIssueFilterParams', { parse: true });
  if (params) {
    return {
      ...getDefaultProjectIssueFilterParams(),
      ...params,
    };
  }
  return getDefaultProjectIssueFilterParams();
}

export function getDefaultProjectIssueConclusionFilterParams() {
  return {};
}

export function getSanitizedProjectIssueFilterParams(params) {
  const p = getSanitizedFilterParams(params);
  if (typeof p.status === 'string') {
    p.status = [p.status];
  }

  return p;
}

const state = {
  projectIssues: [],
  projectIssuePagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  projectIssueFilterParams: getDefaultProjectIssueFilterParams(),
  totalProjectIssuesCount: 0,
  newProjectIssuesCount: 0,
  sentryIssueCounts: [],

  newProjectIssue: getDefaultProjectIssueFormItem(),
  editedProjectIssue: {},
  projectIssueValidationErrors: {},

  projectIssueConclusions: [], // same model as projectIssues
  projectIssueConclusionPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  projectIssueConclusionFilterParams: getDefaultProjectIssueConclusionFilterParams(),
};

const getters = {
  projectIssueStatusesMap() {
    return {
      new: { value: 'new', text: i18n.t('projects.issue_status.new'), color: 'error' },
      seen: { value: 'seen', text: i18n.t('projects.issue_status.seen') },
      in_progress: { value: 'in_progress', text: i18n.t('projects.issue_status.in_progress') },
      // warning: confusing name, translation says finished locally
      ready_for_test: {
        value: 'ready_for_test',
        text: i18n.t('projects.issue_status.ready_for_test'),
      },
      deployed_to_staging: {
        value: 'deployed_to_staging',
        text: i18n.t('projects.issue_status.deployed_to_staging'),
      },
      deployed_to_production: {
        value: 'deployed_to_production',
        text: i18n.t('projects.issue_status.deployed_to_production'),
      },
      postponed: { value: 'postponed', text: i18n.t('projects.issue_status.postponed') },
      closed: { value: 'closed', text: i18n.t('projects.issue_status.closed') },
      rejected: { value: 'rejected', text: i18n.t('projects.issue_status.rejected') },
    };
  },
  projectIssueStatuses(state, getters) {
    return Object.values(getters.projectIssueStatusesMap);
  },

  projectIssuePrioritiesMap() {
    return {
      normal: {
        value: 'normal',
        text: i18n.t('projects.issue_priorities.normal'),
      },
      important: {
        value: 'important',
        text: i18n.t('projects.issue_priorities.important'),
      },
      critical: {
        value: 'critical',
        text: i18n.t('projects.issue_priorities.critical'),
      },
    };
  },
  projectIssuePriorities(state, getters) {
    return Object.values(getters.projectIssuePrioritiesMap);
  },

  projectIssueCategoriesMap() {
    return {
      null: { value: null, text: i18n.t('projects.issue_categories.without_category') },
      log: { value: 'log', text: i18n.t('projects.issue_categories.logic') },
      req: {
        value: 'req',
        text: i18n.t('projects.issue_categories.requirement'),
        description: i18n.t('projects.issue_categories.descriptions.requirement'),
      },
      gui: {
        value: 'gui',
        text: i18n.t('projects.issue_categories.gui'),
        description: i18n.t('projects.issue_categories.descriptions.gui'),
      },
      dsn: {
        value: 'dsn',
        text: i18n.t('projects.issue_categories.design'),
        description: i18n.t('projects.issue_categories.descriptions.design'),
      },
      typ: {
        value: 'typ',
        text: i18n.t('projects.issue_categories.typography'),
        description: i18n.t('projects.issue_categories.descriptions.typography'),
      },
    };
  },
  projectIssueCategories(state, getters) {
    return Object.values(getters.projectIssueCategoriesMap);
  },
};

const mutations = {
  SET_PROJECT_ISSUES(state, { data, meta }) {
    state.projectIssues = data || [];
    state.projectIssuePagination = {
      current_page: meta?.current_page || 1,
      per_page: meta?.per_page || 50,
      total: meta?.total || -1,
    };
  },

  SET_PROJECT_ISSUE_CONCLUSIONS(state, { data, meta }) {
    state.projectIssueConclusions = data || [];
    state.projectIssueConclusionPagination = {
      current_page: meta?.current_page || 1,
      per_page: meta?.per_page || 50,
      total: meta?.total || -1,
    };
  },

  SET_PROJECT_ISSUE_COUNTS(state, data) {
    state.totalProjectIssuesCount = data.intranet.total_issues;
    state.newProjectIssuesCount = data.intranet.new_issues;
    state.sentryIssueCounts = data.sentry;
  },

  SET_PROJECT_ISSUE_FILTER_PARAMS(state, params) {
    state.projectIssueFilterParams = params;
  },

  SET_PROJECT_ISSUE_CONCLUSION_FILTER_PARAMS(state, params) {
    state.projectIssueConclusionFilterParams = params;
  },

  SET_NEW_PROJECT_ISSUE(state, issue) {
    state.newProjectIssue = issue;
  },

  SET_EDITED_PROJECT_ISSUE(state, issue) {
    state.editedProjectIssue = JSON.parse(JSON.stringify(issue));
    state.projectIssueValidationErrors = {};
  },

  SET_PROJECT_ISSUE_VALIDATION_ERRORS(state, errors) {
    state.projectIssueValidationErrors = errors;
  },

  STORE_PROJECT_ISSUE(state, issue) {
    state.projectIssues.unshift(issue);
    state.newProjectIssue = getDefaultProjectIssueFormItem();
    state.projectIssueValidationErrors = {};
    state.projectIssuePagination.totalItems += 1;
  },

  STORE_PROJECT_ISSUE_CONCLUSION(state, issue) {
    state.projectIssueConclusions.unshift(issue);
    state.projectIssueConclusionPagination.totalItems += 1;
  },

  CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.projectIssueValidationErrors, field);
  },

  UPDATE_PROJECT_ISSUE(state, issue) {
    state.projectIssues = updateArrayItem(state.projectIssues, issue);
    if (state.editedProjectIssue.id === issue.id) {
      state.editedProjectIssue = {
        ...state.editedProjectIssue,
        ...issue,
      };
    }
  },

  UPDATE_PROJECT_ISSUE_CONCLUSION(state, issue) {
    state.projectIssueConclusions = updateArrayItem(state.projectIssueConclusions, issue);
  },

  DELETE_PROJECT_ISSUE(state, issue) {
    state.projectIssues = removeArrayItem(state.projectIssues, issue);
    state.projectIssuePagination.totalItems -= 1;
  },

  DELETE_PROJECT_ISSUE_CONCLUSION(state, issue) {
    state.projectIssueConclusions = removeArrayItem(state.projectIssueConclusions, issue);
    state.projectIssueConclusionPagination.totalItems -= 1;
  },
};

const actions = {
  async fetchProjectIssues({ state, commit }, params) {
    commit('SET_PROJECT_ISSUE_FILTER_PARAMS', params);
    const { data } = await projectIssueService.getPage(state.projectIssueFilterParams);
    if (
      data?.data.length &&
      state.projectIssueFilterParams.project_id &&
      +state.projectIssueFilterParams.project_id !== data.data[0].project_id
    ) {
      // fixes an issue when navigating to another project before the previous one has finished loading,
      // sometimes issues from the previous project would be displayed
      return;
    }
    commit('SET_PROJECT_ISSUES', data);
  },

  async fetchProjectIssueConclusions({ state, commit }, params) {
    commit('SET_PROJECT_ISSUE_CONCLUSION_FILTER_PARAMS', { ...params });
    const { data } = await projectIssueService.getPage({
      ...state.projectIssueConclusionFilterParams,
      status: ['closed'],
    });
    commit('SET_PROJECT_ISSUE_CONCLUSIONS', data);
  },

  async fetchTotalProjectIssuesCount({ commit, rootGetters }) {
    if (rootGetters['auth/currentUser']?.role === 'client') {
      return;
    }
    const { data } = await projectIssueService.getTotalIssuesCount();

    commit('SET_PROJECT_ISSUE_COUNTS', data);
  },

  async markProjectIssueAsSeen({ state, commit }, issue) {
    const { data } = await projectIssueService.getById(issue.id);
    commit('UPDATE_PROJECT_ISSUE', data);
    if (issue.id === state.editedProjectIssue.id) {
      commit('SET_EDITED_PROJECT_ISSUE', data);
    }
  },

  async editProjectIssue({ state, commit }, issueId) {
    const projectIssue = state.projectIssues?.find((c) => c.id === issueId);
    if (projectIssue) {
      commit('SET_EDITED_PROJECT_ISSUE', projectIssue);
      return;
    }
    const { data } = await projectIssueService.getById(issueId);
    commit('SET_EDITED_PROJECT_ISSUE', data);
  },

  async storeProjectIssue({ rootGetters, commit, dispatch }, issue) {
    try {
      const response = await projectIssueService.create(issue);

      // to access loading of image uploading for
      // $store.getters.loading[`post:api/project-issues-image/${this.editedProjectIssue.id}`]
      commit('SET_EDITED_PROJECT_ISSUE', response.data);
      commit('STORE_PROJECT_ISSUE', response.data);
      dispatch('fetchTotalProjectIssuesCount');
      return response.data;
    } catch (err) {
      const errors = mapErrorsToInputs(err);
      errors.files = collectMultiSelectErrors(errors, 'file');
      commit('SET_PROJECT_ISSUE_VALIDATION_ERRORS', errors);
      throw err;
    }
  },

  async updateProjectIssue({ commit, dispatch }, issue) {
    try {
      const response = await projectIssueService.update(issue);
      commit('UPDATE_PROJECT_ISSUE', response.data);
      commit('UPDATE_PROJECT_ISSUE_CONCLUSION', response.data);
      if (response.data.status === 'closed') {
        commit('STORE_PROJECT_ISSUE_CONCLUSION', response.data);
      }
      dispatch('fetchTotalProjectIssuesCount');
      return response.data;
    } catch (err) {
      commit('SET_PROJECT_ISSUE_VALIDATION_ERRORS', mapErrorsToInputs(err));
      throw err;
    }
  },

  async uploadProjectIssueAttachments({ state, commit }, { issue, files }) {
    try {
      const response = await projectIssueService.uploadAttachments(issue, files);
      const attachments = issue.attachments || [];
      attachments.push(...response.data);
      const updatedIssue = {
        ...issue,
        attachments,
      };
      commit('UPDATE_PROJECT_ISSUE', updatedIssue);
    } catch (err) {
      const errors = mapErrorsToInputs(err);
      errors.files = collectMultiSelectErrors(errors, 'file');
      commit('SET_PROJECT_ISSUE_VALIDATION_ERRORS', errors);
      throw err;
    }
  },

  async concludeProjectIssue({ commit, dispatch }, issue) {
    try {
      const issueConcludeResponse = await projectIssueService.conclude(issue);
      const issueUpdateResponse = await projectIssueService.update({
        ...issueConcludeResponse.data,
        status: 'closed',
      });
      commit('UPDATE_PROJECT_ISSUE', issueUpdateResponse.data);
      commit('STORE_PROJECT_ISSUE_CONCLUSION', issueUpdateResponse.data);
      openSnackbar(i18n.t('general.entry_updated'));
      dispatch('fetchTotalProjectIssuesCount');
    } catch (err) {
      commit('SET_PROJECT_ISSUE_VALIDATION_ERRORS', mapErrorsToInputs(err));
      throw err;
    }
  },

  async convertProjectIssueToUserStory(
    { state, commit, dispatch },
    { projectIssue, projectUserStory }
  ) {
    let response;
    try {
      response = await projectIssueService.convertToUserStory(projectIssue, projectUserStory);

      commit('projectUserStories/STORE_PROJECT_USER_STORY', response.data.project_user_story, {
        root: true,
      });
      if (state.projectIssueFilterParams.status?.includes('rejected')) {
        commit('UPDATE_PROJECT_ISSUE', response.data.project_issue);
      } else {
        commit('DELETE_PROJECT_ISSUE', response.data.project_issue);
      }

      openSnackbar(i18n.t('projects.user_story_created'));
      dispatch('fetchTotalProjectIssuesCount');
    } catch (err) {
      commit(
        'projectUserStories/SET_PROJECT_USER_STORY_VALIDATION_ERRORS',
        mapErrorsToInputs(err),
        { root: true }
      );
      throw err;
    }
    return response.data;
  },

  async deleteProjectIssueAttachment({ state, commit }, { issue, attachment }) {
    await projectIssueService.deleteAttachment(issue, attachment);
    openSnackbar(i18n.t('general.file_deleted'));
    const updatedIssue = {
      ...issue,
      attachments: issue.attachments.filter((a) => a.id !== attachment.id),
    };
    commit('UPDATE_PROJECT_ISSUE', updatedIssue);
    if (state.editedProjectIssue.id === updatedIssue.id) {
      commit('SET_EDITED_PROJECT_ISSUE', updatedIssue);
    }
  },

  async updateProjectIssueStatus({ commit, dispatch }, issue) {
    const { data } = await projectIssueService.updateStatus(issue);
    if (data.status === 'closed') {
      commit('STORE_PROJECT_ISSUE_CONCLUSION', data);
    }
    commit('UPDATE_PROJECT_ISSUE', data);
    openSnackbar(i18n.t('general.entry_updated'));
    dispatch('fetchTotalProjectIssuesCount');
  },

  async deleteProjectIssue({ commit, dispatch }, issue) {
    const hasConfirmed = await openConfirmDialog();
    if (!hasConfirmed) {
      return 'cancelled';
    }
    await projectIssueService.delete(issue);
    commit('DELETE_PROJECT_ISSUE', issue);
    commit('DELETE_PROJECT_ISSUE_CONCLUSION', issue);
    openSnackbar(i18n.t('general.entry_deleted'));
    dispatch('fetchTotalProjectIssuesCount');
    return 'deleted';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
