import projectService from '@/api/project-service';

export const getDefaultProjectBurnUpStatisticsFilterParams = (assignees = []) => ({
  user_ids: assignees.map((assignee) => assignee.user_id),
  date_from: '', // format(addDays(new Date(), -90), 'yyyy-MM-dd')
  date_to: '',
});

const state = {
  projectBurnUpStatistics: [],
  projectBurnUpStatisticsFilterParams: getDefaultProjectBurnUpStatisticsFilterParams(),
};

const getters = {};

const mutations = {
  SET_PROJECT_BURN_UP_STATISTICS(state, data) {
    state.projectBurnUpStatistics = data;
  },

  SET_PROJECT_BURN_UP_STATISTICS_FILTER_PARAMS(state, params) {
    state.projectBurnUpStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchProjectBurnUpStatistics({ commit }, { projectId, params }) {
    commit('SET_PROJECT_BURN_UP_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectService.getProjectBurnUpStatistics(projectId, params);
    commit('SET_PROJECT_BURN_UP_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
