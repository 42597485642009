<template>
  <v-tooltip
    v-if="!!testStatus"
    :disabled="!tooltipText"
    :key="tooltipText"
    content-class="tooltip-in-dialog"
    bottom
  >
    <template #activator="{ on }">
      <v-icon
        v-on="on"
        :color="statusDetails.color"
        :class="iconClass"
        small
        @click.stop
        @mouseenter="fetchTestedByDetails"
        >{{ statusDetails.icon }}</v-icon
      >
    </template>
    <div class="d-flex align-center">
      <span>{{ tooltipText }}</span>
    </div>
  </v-tooltip>
</template>

<script>
import userService from '@/api/user-service';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ProjectUserStoryTestStatusIndicator',

  props: {
    testStatus: {
      type: String,
      default: '',
    },
    testedBy: {
      type: Number,
      default: 0,
    },
    iconClass: {
      type: [String, Array, Object],
      default: '',
    },
  },

  data() {
    return {
      loadingTestedBy: false,
    };
  },

  computed: {
    ...mapState('users', ['userFullNamesMap']),
    ...mapGetters('projectUserStories', ['projectUserStoryTestStatuses']),

    statusDetails() {
      return (
        this.projectUserStoryTestStatuses.find((s) => s.value === this.testStatus) ||
        this.projectUserStoryTestStatuses[0]
      );
    },

    tooltipText() {
      if (!this.testStatus || this.loadingTestedBy) {
        return '';
      }

      return this.$t('projects.test_status_tested_by')
        .replace('{0}', this.statusDetails.text)
        .replace('{1}', this.testedByFullName);
    },

    testedByFullName() {
      return this.userFullNamesMap[this.testedBy] || '';
    },
  },

  methods: {
    ...mapMutations('users', ['SET_USER_FULL_NAME']),

    async fetchTestedByDetails() {
      if (!this.testedBy || this.loadingTestedBy || this.testedByFullName) {
        return;
      }

      this.loadingTestedBy = true;
      try {
        const { data } = await userService.getFullNameById(this.testedBy);
        this.SET_USER_FULL_NAME({ userId: this.testedBy, fullName: data.full_name });
      } catch (e) {
        // handled by generic http error handler
      }
      this.loadingTestedBy = false;
    },
  },
};
</script>

<style scoped></style>
