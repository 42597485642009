<template>
  <v-tooltip
    v-if="!projectUserStory.sprint_id && projectUserStory.status === 'client_approved'"
    bottom
  >
    <template #activator="{ on }">
      <v-icon v-on="on" color="success" class="mr-1 mt-1 mt-sm-0" small @click.stop
        >check_circle</v-icon
      >
    </template>
    <div>
      {{ $t('projects.user_story_status.client_approved') }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ProjectUserStoryClientApprovalStatusIndicator',

  props: {
    projectUserStory: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
