import httpClient from '@/api/http-client';

const endpoint = 'api/finances/planned-flows';

const plannedFinanceFlowService = {
  getAll: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  create: (plannedFlow) => httpClient.post(endpoint, plannedFlow),

  update: (plannedFlow) => httpClient.put(`${endpoint}/${plannedFlow.id}`, plannedFlow),

  delete: (plannedFlow) => httpClient.delete(`${endpoint}/${plannedFlow.id}`),
};

export default plannedFinanceFlowService;
