import httpClient from '@/api/http-client';

const endpoint = 'api/project-user-stories';

const projectUserStoryService = {
  getAll: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getClosedBy: (id, pointsType) =>
    httpClient.get(`${endpoint}/${id}/closed`, { params: { type: pointsType } }),

  getPointStatistics: (params) => httpClient.get(`${endpoint}/statistics`, { params }),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  create: (projectUserStory) => httpClient.post(endpoint, projectUserStory),

  split: (oldSprintId, newSprintId) =>
    httpClient.post(`${endpoint}-split`, {
      old_sprint_id: oldSprintId,
      new_sprint_id: newSprintId,
    }),

  createBulk: (payload) => httpClient.post(`${endpoint}/bulk-insert`, payload),

  update: (projectUserStory) =>
    httpClient.put(`${endpoint}/${projectUserStory.id}`, projectUserStory),

  addToSprint: (projectUserStory, insertAfterProjectUserStoryId, sprintId) => {
    const payload = {};
    if (insertAfterProjectUserStoryId) {
      payload.insert_after_user_story_id = insertAfterProjectUserStoryId;
    }

    return httpClient.put(`${endpoint}/${projectUserStory.id}/add-to-sprint/${sprintId}`, payload);
  },

  removeFromSprint: (projectUserStory, insertAfterProjectUserStoryId) => {
    const payload = {};
    if (insertAfterProjectUserStoryId) {
      payload.insert_after_user_story_id = insertAfterProjectUserStoryId;
    }
    return httpClient.put(`${endpoint}/${projectUserStory.id}/remove-from-sprint`, payload);
  },

  reorder: (projectUserStories) =>
    httpClient.post(`${endpoint}/reorder`, { user_story_ids: projectUserStories.map((s) => s.id) }),

  move: (userStoryIds, projectId, sprintId) => {
    const payload = {
      project_id: projectId,
      sprint_id: sprintId,
      user_story_ids: userStoryIds,
    };

    return httpClient.post(`${endpoint}/move`, payload);
  },

  massUpdateDeployStatus: (payload) => {
    const { deploy_status } = payload;
    const transformedPayload = {
      ...payload,
      deploy_status: deploy_status === 'null' ? null : deploy_status,
    };
    return httpClient.patch(`${endpoint}/change-deploy-status`, transformedPayload);
  },

  massUpdateTestStatus: (payload) => {
    return httpClient.patch(`${endpoint}/change-test-status`, payload);
  },

  updateDescription: (id, description) =>
    httpClient.put(`${endpoint}/${id}/update-description`, { description }),

  updatePointsStatus: (projectUserStory, pointsType, newStatus) => {
    const payload = {
      points_type: pointsType,
      new_status: newStatus,
    };
    return httpClient.put(`${endpoint}/${projectUserStory.id}/points`, payload);
  },

  delete: (projectUserStory) => httpClient.delete(`${endpoint}/${projectUserStory.id}`),
};

export default projectUserStoryService;
