import Vue from 'vue';
import projectService from '@/api/project-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import projectIssueService from '@/api/project-issue-service';
import { getCachedProjectIssueFilterParams } from '@/store/modules/project-issues-module';
import projectDocumentationService from '@/api/project-documentation-service';

export const getDefaultProjectFormItem = () => ({
  is_private: false,
  assignees: [
    {
      permission: 'employee',
    },
  ],
  status: 'planned',
});

export const getDefaultProjectFilterParams = () => ({});

const state = {
  projects: [],
  projectPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  selectedProject: {},
  newProject: getDefaultProjectFormItem(),
  projectValidationErrors: {},
  projectFilterParams: getDefaultProjectFilterParams(),
};

const getters = {
  projectStatusesForFiltersMap() {
    return {
      new: {
        value: 'new',
        text: i18n.t('projects.project_status.new'),
      },
      planned: {
        value: 'planned',
        text: i18n.t('projects.project_status.planned'),
      },
      active: {
        value: 'active',
        text: i18n.t('projects.project_status.active'),
      },
      support: {
        value: 'support',
        text: i18n.t('projects.project_status.support'),
      },
      ended: {
        value: 'ended',
        text: i18n.t('projects.project_status.ended'),
      },
    };
  },
  projectStatusesForFilters(state, getters) {
    return Object.values(getters.projectStatusesForFiltersMap);
  },

  projectStatuses() {
    return ['planned', 'active', 'support', 'ended'].map((s) => ({
      value: s,
      text: i18n.t(`projects.project_status.${s}`),
    }));
  },

  projectPermissions() {
    return [
      { text: i18n.t('projects.assignee_permissions.admin'), value: 'admin', disabled: true },
      { text: i18n.t('projects.assignee_permissions.employee'), value: 'employee' },
      { text: i18n.t('projects.assignee_permissions.client'), value: 'client' },
    ];
  },

  currentUserPermissionInSelectedProject(state, getters, rootState, rootGetters) {
    const { id, role } = rootGetters['auth/currentUser'];

    if (role === 'admin') {
      return 'admin';
    }

    const { assignees } = state.selectedProject;
    for (let i = 0; i < assignees?.length; i++) {
      if (assignees[i].user_id === id) {
        return assignees[i].permission;
      }
    }

    return '';
  },
};

const mutations = {
  SET_PROJECTS(state, { data, meta }) {
    const { current_page, per_page, total } = meta;
    state.projects = data;
    state.projectPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_PROJECT_FILTER_PARAMS(state, params) {
    state.projectFilterParams = params;
  },

  SET_NEW_PROJECT(state, project) {
    state.newProject = project;
  },

  SET_SELECTED_PROJECT(state, project) {
    state.projectValidationErrors = {};
    state.selectedProject = JSON.parse(JSON.stringify(project));
  },

  STORE_PROJECT(state, project) {
    state.projects.unshift(project);
    state.projectPagination.total += 1;
    state.projectValidationErrors = {};
    state.newProject = getDefaultProjectFormItem();
  },

  UPDATE_PROJECT(state, project) {
    state.projects = updateArrayItem(state.projects, project);

    if (project.id === state.selectedProject.id) {
      state.selectedProject = project;
    }
  },

  DELETE_PROJECT(state, project) {
    state.projects = removeArrayItem(state.projects, project);
    state.projectPagination.total -= 1;
  },

  SET_PROJECT_VALIDATION_ERRORS(state, projectValidationErrors) {
    state.projectValidationErrors = projectValidationErrors;
  },

  CLEAR_PROJECT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.projectValidationErrors, field);
  },
};

const actions = {
  fetchProjects({ commit }, params) {
    commit('SET_PROJECT_FILTER_PARAMS', params);
    return projectService.getPage(params).then((res) => {
      commit('SET_PROJECTS', res.data);
      return res.data;
    });
  },

  storeProject({ commit }, project) {
    return projectService
      .create(project)
      .then((res) => {
        commit('STORE_PROJECT', res.data);
        openSnackbar(i18n.t('projects.messages.project_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async selectProject({ commit }, projectId) {
    let project = {};
    let projectDocumentations = [];
    let projectIssues = {};

    const issueParams = { ...getCachedProjectIssueFilterParams(), project_id: projectId };

    const requests = [
      projectService.getById(projectId).then((res) => {
        project = res.data;
      }),
      projectIssueService.getPage(issueParams).then((res) => {
        projectIssues = res.data;
      }),
      projectDocumentationService.getAll(projectId).then((res) => {
        projectDocumentations = res.data;
      }),
    ];

    await Promise.all(requests);
    commit('SET_SELECTED_PROJECT', project);
    commit('projectIssues/SET_PROJECT_ISSUES', projectIssues, { root: true });
    commit('projectIssues/SET_PROJECT_ISSUE_FILTER_PARAMS', issueParams, { root: true });
    commit('projectDocumentations/SET_PROJECT_DOCUMENTATIONS', projectDocumentations, {
      root: true,
    });
  },

  editProject({ state, commit }, projectId) {
    const project = state.projects?.find((c) => c.id === projectId);
    if (project) {
      commit('SET_SELECTED_PROJECT', project);
      return Promise.resolve(project);
    }
    if (state.selectedProject.id === projectId) {
      return Promise.resolve(project);
    }
    return projectService.getById(projectId).then((res) => {
      commit('SET_SELECTED_PROJECT', res.data);
      commit('UPDATE_PROJECT', res.data);
      return res.data;
    });
  },

  updateProject({ commit }, project) {
    return projectService
      .update(project)
      .then((res) => {
        commit('UPDATE_PROJECT', res.data);
        openSnackbar(i18n.t('projects.messages.project_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  updateProjectAssignees({ state, commit }, assignees) {
    return projectService
      .updateAssignees(
        state.selectedProject.id,
        assignees.filter((a) => !!a.user_id)
      )
      .then((res) => {
        commit('UPDATE_PROJECT', {
          ...state.selectedProject,
          assignees: res.data.assignees,
        });
        openSnackbar(i18n.t('projects.messages.team_updated'));
        return res.data;
      });
  },

  async updateProjectAssigneeSettings({ state, commit }, assignee) {
    const { data } = await projectService.updateProjectSettings(assignee);
    commit('UPDATE_PROJECT', {
      ...state.selectedProject,
      assignees: state.selectedProject.assignees.map((a) => (a.id === data.id ? data : a)),
    });
    openSnackbar(i18n.t('projects.settings_updated'));
  },

  deleteProject({ commit }, project) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      projectService.delete(project).then(() => {
        commit('DELETE_PROJECT', project);
        openSnackbar(i18n.t('projects.messages.project_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
