import httpClient from '@/api/http-client';

const endpoint = 'api/www-resources';

const passwordService = {
  model: 'password',

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),
};

export default passwordService;
