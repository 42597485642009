<template>
  <v-chip
    :color="userStoryStatusColors[displayedStatus]"
    class="font-weight-medium black--text full-opacity"
    disabled
    small
  >
    {{ $t(`projects.user_story_status.${displayedStatus}`) }}
  </v-chip>
</template>

<script>
export default {
  name: 'ProjectUserStoryPointsClientStatus',

  props: {
    userStory: {
      type: Object,
      required: true,
    },
  },

  computed: {
    userStoryStatusColors() {
      return {
        new: 'grey lighten-4',
        client_approved: 'grey lighten-4',
        in_progress: 'blue lighten-4',
        done: 'green lighten-4',
      };
    },

    displayedStatus() {
      const { status, deploy_statuses } = this.userStory;
      if (status === 'client_approved') {
        return 'new';
      }

      for (let i = 0; i < deploy_statuses.length; i++) {
        if (deploy_statuses[i].status === 'staging') {
          return 'in_progress';
        }
      }

      return this.userStory.status;
    },
  },
};
</script>

<style scoped></style>
