<template>
  <v-expansion-panel class="mb-3">
    <v-expansion-panel-header
      :class="{ 'default-cursor': isAlwaysExpanded }"
      expand-icon=""
      class="project-page-panel-header py-2"
    >
      <slot name="title">
        <div class="text-subtitle-2">{{ $t('general.issues').toUpperCase() }}</div>
      </slot>
      <div class="d-flex align-center justify-end">
        <ProjectIssueFilter
          v-if="!$isClient()"
          :filter-params="projectIssueFilterParams"
          :employees="selectedProject.assignees"
          :hide-project-filter="!!selectedProject.id"
          hide-chips
          @change="onFilterChange"
        />
        <v-btn
          v-if="canCreate && selectedProject.id"
          class="mr-1"
          icon
          @click.stop="$router.push({ name: 'project_createProjectIssue' })"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-expansion-panel-header>

    <v-expansion-panel-content class="project-page-panel mt-0">
      <ProjectIssueList
        :items="projectIssues"
        :pagination="projectIssuePagination"
        :loading="
          loading['get:api/project-issues'] && !loading[`get:api/projects/${nextProjectId}`]
        "
        :show-project-link="showProjectLinks"
        can-change-status
        @click:row="onIssueClick"
        @update:status="onProjectIssueStatusChange"
        @updated:is-resolved="UPDATE_PROJECT_ISSUE"
        @update:page="fetchProjectIssues({ ...projectIssueFilterParams, page: $event })"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ProjectIssueList from '@/components/ProjectIssueList.vue';
import ProjectIssueFilter from '@/components/filters/ProjectIssueFilter.vue';
import clone from 'just-clone';

export default {
  name: 'ProjectIssuesPanel',

  components: { ProjectIssueFilter, ProjectIssueList },

  props: {
    // to avoid double opacity decrease due to multiple .content-loading layers
    nextProjectId: {
      type: [Number, String],
      default: null,
    },
    selectedProject: {
      type: Object,
      default: () => ({}),
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    isAlwaysExpanded: {
      type: Boolean,
      default: false,
    },
    showProjectLinks: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('projectIssues', [
      'projectIssues',
      'projectIssuePagination',
      'projectIssueFilterParams',
    ]),
    ...mapGetters('projectIssues', ['projectIssuePriorities', 'projectIssueStatuses']),
    ...mapGetters(['loading']),
  },

  methods: {
    ...mapMutations('projectIssues', ['SET_PROJECT_ISSUE_FILTER_PARAMS', 'UPDATE_PROJECT_ISSUE']),
    ...mapActions('projectIssues', ['updateProjectIssueStatus', 'fetchProjectIssues']),

    onProjectIssueStatusChange(newStatus, issue) {
      if (newStatus === issue.status) {
        return;
      }

      if (newStatus === 'closed') {
        this.$router.push({
          name: 'project_concludeProjectIssue',
          params: { projectIssueId: issue.id },
        });
      } else {
        this.updateProjectIssueStatus({
          ...issue,
          status: newStatus,
        });
      }
    },

    onFilterChange(params) {
      this.fetchProjectIssues({
        ...params,
        project_id: params.project_id || this.selectedProject.id,
      });

      if (!this.$route.query['is-filter-open']) {
        return;
      }

      const query = clone(this.$route.query);
      delete query['is-filter-open'];
      this.$router.push({
        ...this.$route,
        query,
      });
    },

    onIssueClick(issue) {
      this.$emit('click:issue', issue);
    },
  },
};
</script>

<style scoped></style>
