<template>
  <div>
    <v-tooltip :z-index="tooltipZIndex" bottom>
      <template #activator="{ on }">
        <v-avatar v-on="on" :color="avatarColor" size="32">
          {{ initials }}
        </v-avatar>
      </template>
      <div>
        {{ tooltipText }}
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',

  props: {
    fullName: {
      type: String,
      default: '',
    },
    tooltip: {
      // default is user initials. Use this prop to override that.
      type: String,
      default: '',
    },
    tooltipPrefix: {
      // Prefixes this to user initials. Gets overriden by tooltip prop
      type: String,
      default: '',
    },
    tooltipZIndex: {
      type: Number,
      default: 2,
    },
    color: {
      type: String,
      default: '',
    },
  },

  computed: {
    initials() {
      if (!this.fullName) {
        return '';
      }
      return this.fullName
        .split(' ')
        .map((part) => part.slice(0, 1).toUpperCase())
        .join('');
    },

    tooltipText() {
      return this.tooltip || `${this.tooltipPrefix}${this.fullName}`;
    },

    avatarColor() {
      if (this.color) {
        return this.color;
      }

      return this.$vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-2';
    },
  },
};
</script>

<style scoped></style>
