<template>
  <BaseFilePreviewFormLayout
    :file="item.file"
    :title="formTitle"
    :disabled="disabled"
    @cancel="$emit('cancel')"
    @save="$emit('save', item)"
  >
    <v-row dense>
      <v-col v-if="!item.id" cols="12">
        <v-file-input
          v-model="item.file"
          :error-messages="errors['file']"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
          :accept="clientAgreementFileFormats"
          @change="formMixin_clearErrors('file')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <BaseDatepickerInput
          v-model="item.document_date"
          :error-messages="errors['document_date']"
          :label="$t('documents.labels.document_date')"
          @input="formMixin_clearErrors('document_date')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <ContactAutocomplete
          v-model="item.client_id"
          :error-messages="errors['client_id']"
          :initial-item="item.client"
          :label="formMixin_getRequiredFieldLabel($t('general.contact'))"
          show-create-button
          @input="formMixin_clearErrors('client_id')"
          @update:initial-item="item.client = $event"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-select
          v-model="item.type"
          :items="clientAgreementTypes"
          :error-messages="errors.type"
          :label="formMixin_getRequiredFieldLabel($t('documents.labels.type_of_agreement'))"
          @input="formMixin_clearErrors('type')"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="6">
        <ProjectAutocomplete
          v-model="item.project_id"
          :initial-item="item.project"
          :error-messages="errors.project_id"
          :label="formMixin_getRequiredFieldLabel($t('general.project'))"
          @input="
            onProjectSelect($event);
            formMixin_clearErrors('project_id');
          "
        />
      </v-col>

      <v-col v-if="item.project_id && mustSelectSprint" cols="12" sm="6">
        <v-select
          v-model="item.sprint_id"
          :items="sprints"
          :error-messages="errors.sprint_id"
          :label="$t('general.sprint')"
          item-text="sprint_name"
          item-value="id"
          @input="formMixin_clearErrors('sprint_id')"
        />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="6">
        <v-checkbox
          v-model="item.is_signatures_needed"
          :error-messages="errors.is_signatures_needed"
          :label="$t('documents.labels.signature_needed')"
          @change="formMixin_clearErrors('is_signatures_needed')"
        />
      </v-col>

      <v-col cols="12" sm="6">
        <v-checkbox
          v-if="item.is_signatures_needed"
          v-model="item.is_signed_by_all_signees"
          :error-messages="errors.is_signed_by_all_signees"
          :label="$t('documents.labels.signed')"
          @change="formMixin_clearErrors('is_signed_by_all_signees')"
        />
      </v-col>
    </v-row>
  </BaseFilePreviewFormLayout>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput.vue';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete.vue';
import projectSprintService from '@/api/project-sprint-service';
import { mapGetters } from 'vuex';
import ProjectAutocomplete from '@/components/autocompletes/ProjectAutocomplete.vue';
import BaseFilePreviewFormLayout from '@/components/base/BaseFilePreviewFormLayout.vue';

export default {
  name: 'ClientAgreementForm',

  components: {
    BaseFilePreviewFormLayout,
    ProjectAutocomplete,
    ContactAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    clientAgreement: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
      sprints: [],
    };
  },

  computed: {
    ...mapGetters('clientAgreements', ['clientAgreementTypes', 'clientAgreementFileFormats']),

    mustSelectSprint() {
      return this.item.type.includes('sprint');
    },

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.clientAgreement.id ? 'edit' : 'new'}_client_agreement`
      );
    },
  },

  created() {
    const item = clone(this.clientAgreement);
    if (item.id) {
      item.type = item.model.type;
      item.client_id = item.model.client_id;
      item.project_id = item.model.project_id;
      item.sprint_id = item.model.sprint_id;
      item.client = item.model.client;
      item.project = item.model.project;
      item.sprint = item.model.sprint;
      if (item.sprint) {
        this.sprints.push(item.sprint);
      }
    }
    this.item = item;
  },

  methods: {
    async getProjectSprints(projectId) {
      const { data } = await projectSprintService.getAll({ project_id: projectId });
      this.sprints = data;
    },

    onProjectSelect(projectId) {
      if (projectId) {
        this.getProjectSprints(projectId);
      } else {
        this.sprints = [];
      }
    },
  },
};
</script>
