import httpClient from '@/api/http-client';
import clone from 'just-clone';

const endpoint = 'api/projects';

function getTransformedProjectRequestBody(project) {
  const requestBody = JSON.parse(JSON.stringify(project));
  const count = requestBody.assignees.length;
  requestBody.assignees = [];
  for (let i = 0; i < count; i++) {
    if (project.assignees[i].user_id && project.assignees[i].permission) {
      requestBody.assignees.push({
        user_id: project.assignees[i].user_id,
        permission: project.assignees[i].permission,
      });
    }
  }
  return requestBody;
}

const projectService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getActive: () => httpClient.get('api/active-projects'),

  getProjectBurnUpStatistics: (id, payload) => {
    const requestBody = clone(payload);
    if (!requestBody.date_from) {
      delete requestBody.date_from;
    }
    if (!requestBody.date_to) {
      delete requestBody.date_to;
    }
    return httpClient.post(`${endpoint}/${id}/time-spent`, requestBody);
  },

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  updateProjectSettings: (assignee) =>
    httpClient.put(`${endpoint}/assignees/${assignee.id}/user-settings`, {
      message_on_issue: assignee.message_on_issue,
      language: assignee.language,
    }),

  create: (project) => httpClient.post(endpoint, getTransformedProjectRequestBody(project)),

  update: (project) =>
    httpClient.put(`${endpoint}/${project.id}`, getTransformedProjectRequestBody(project)),

  updateAssignees: (projectId, assignees) =>
    httpClient.put(`${endpoint}/assignees/${projectId}`, { assignees }),

  delete: (project) => httpClient.delete(`${endpoint}/${project.id}`),
};

export default projectService;
