import Vue from 'vue';
import Router from 'vue-router';
import AppWrapper from '../components/app-layout/AppWrapper.vue';
import Login from '../components/Login.vue';
import Impersonate from '../views/Impersonate.vue';
import { authGuard, loginGuard, permissionsGuard, roleGuard } from './guards';
import store from '../store';
import { invoiceRoutes } from '@/router/routes/invoice-routes';
import { internalDocumentRoutes } from '@/router/routes/internal-document-routes';
import { clientAgreementRoutes } from '@/router/routes/client-agreement-routes';
import { commercialOfferRoutes } from '@/router/routes/commercial-offer-routes';
import { companyRequestRoutes } from '@/router/routes/company-request-routes';
import { employeeDocumentRoutes } from '@/router/routes/employee-document-routes';
import { receivedInvoiceRoutes } from '@/router/routes/received-invoice-routes';
import { sentInvoiceRoutes } from '@/router/routes/sent-invoice-routes';
import { supplierAgreementRoutes } from '@/router/routes/supplier-agreement-routes';
import { projectRoutes } from '@/router/routes/project-routes';
import { projectsStatisticsRoutes } from '@/router/routes/projects-statistics-routes';
import { userRoutes } from '@/router/routes/user-routes';
import { profileRoutes } from '@/router/routes/profile-routes';
import { dashboardRoutes } from '@/router/routes/dashboard-routes';
import { financePlannerRoutes } from '@/router/routes/finance-planner-routes';
import { onRouteChange } from '@/router/navigate';

Vue.use(Router);

const router = new Router({
  mode: import.meta.env.VITE_APP_HTML_HISTORY_MODE ? 'history' : '',

  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: loginGuard,
      meta: { isPublic: true },
    },
    {
      path: '/impersonate',
      name: 'impersonate',
      component: Impersonate,
      meta: { isPublic: true },
    },
    {
      path: '/public-issues/:hash',
      name: 'publicProjectIssue',
      component: () =>
        import(
          /* webpackChunkName: "publicProjectIssue" */ '../views/project-issues/PublicProjectIssue.vue'
        ),
      meta: { isPublic: true },
    },
    {
      path: '/',
      component: AppWrapper,
      children: [
        ...dashboardRoutes,
        {
          path: 'calendar',
          name: 'calendar',
          meta: { requiredPermissions: ['calendar.*'] },
          component: () =>
            import(/* webpackChunkName: "calendar" */ '../legacy-components/calendar/Calendar.vue'),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'passwords',
          name: 'passwords',
          meta: { requiredPermissions: ['password_manager.*'] },
          component: () =>
            import(
              /* webpackChunkName: "passwords" */ '../legacy-components/password-manager/PasswordManager.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'crm',
          name: 'crm',
          meta: {
            menuGroup: 'contacts',
            allowedRoles: ['admin'],
          },
          component: () =>
            import(/* webpackChunkName: "crm" */ '../legacy-components/leads/Leads.vue'),
          beforeEnter: roleGuard,
        },
        {
          path: 'time-tracking',
          name: 'timeTracking',
          meta: { menuGroup: 'project-management', requiredPermissions: ['projects.view.*'] },
          component: () =>
            import(
              /* webpackChunkName: "timeTracking" */ '../views/time-tracking/TimeTracking.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'client-surveys',
          name: 'client-surveys',
          meta: {
            menuGroup: 'contacts',
            allowedRoles: ['admin'],
          },
          component: () =>
            import(
              /* webpackChunkName: "client-surveys" */ '../legacy-components/client-surveys/ClientSurveysWrapper.vue'
            ),
          beforeEnter: roleGuard,
        },
        {
          path: 'strategic-plan',
          redirect: 'strategic-plan/goals',
        },
        {
          path: 'strategic-plan/:tab',
          name: 'strategic-plan',
          meta: { menuGroup: 'company-management', requiredPermissions: ['strategic_plan.*'] },
          component: () =>
            import(
              /* webpackChunkName: "strategic-plan" */ '../legacy-components/company-management/StrategicPlanWrapper.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'expenses',
          name: 'expenses',
          meta: {
            menuGroup: 'finances',
            requiredPermissions: [
              'expenses.items.*',
              'expenses.assignments.*',
              'expenses.categories.*',
            ],
          },
          component: () =>
            import(
              /* webpackChunkName: "expenses-wrapper" */ '../legacy-components/expenses/ExpensesWrapper.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'project-warranties',
          name: 'project-warranties',
          meta: { menuGroup: 'finances' },
          component: () =>
            import(
              /* webpackChunkName: "project-warranties" */ '../legacy-components/project-warranties/ProjectWarranties.vue'
            ),
          beforeEnter: authGuard,
        },
        {
          path: 'employees',
          name: 'employees',
          meta: { menuGroup: 'company-management', requiredPermissions: ['employees.*'] },
          component: () =>
            import(
              /* webpackChunkName: "employees" */ '../legacy-components/employees/Employees.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'employees/:id',
          name: 'employee',
          meta: {
            menuGroup: 'company-management',
            requiredPermissions: ['employees.*'],
            noLoadingBar: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "employee" */ '../legacy-components/employees/profile/Employee.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'employees/:id/activation',
          name: 'employee-activation',
          meta: {
            menuGroup: 'company-management',
            requiredPermissions: ['employees.*'],
            noLoadingBar: true,
          },
          component: () =>
            import(
              /* webpackChunkName: "employee" */ '../legacy-components/employees/profile/Employee.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'candidates',
          name: 'candidates',
          meta: { menuGroup: 'company-management', requiredPermissions: [] },
          component: () =>
            import(
              /* webpackChunkName: "candidates" */ '../legacy-components/candidates/Positions.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'infrastructure-health',
          name: 'infrastructure-health',
          meta: { menuGroup: 'external-systems', requiredPermissions: [] },
          component: () =>
            import(
              /* webpackChunkName: "infrastructure-health" */ '../views/infrastructures/InfrastructureHealth.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        {
          path: 'client-testimonials',
          name: 'client-testimonials',
          meta: {
            menuGroup: 'external-systems',
            menuSubGroup: 'evolvo-eu-management',
            requiredPermissions: [],
          },
          component: () =>
            import(
              /* webpackChunkName: "client-testimonials" */ '../legacy-components/client-testimonials/ClientTestimonials.vue'
            ),
          beforeEnter: permissionsGuard,
        },
        ...invoiceRoutes,
        ...clientAgreementRoutes,
        ...commercialOfferRoutes,
        ...companyRequestRoutes,
        ...employeeDocumentRoutes,
        ...financePlannerRoutes,
        ...internalDocumentRoutes,
        ...receivedInvoiceRoutes,
        ...sentInvoiceRoutes,
        ...supplierAgreementRoutes,
        ...projectRoutes,
        ...projectsStatisticsRoutes,
        ...userRoutes,
        ...profileRoutes,
        {
          path: '*',
          redirect: '/',
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.isPublic) {
    next();
    return;
  }
  if (!store.getters['auth/impersonatedUserId']) {
    await store.dispatch('auth/tryAutoLogin');
  }
  if (to.name !== 'login') {
    // for restoring the visited after logging in
    store.commit('auth/SET_LAST_ACCESSED_ROUTE', to);
  }
  onRouteChange(to, from);
  next();
});

export default router;
