import Home from '@/views/Home.vue';
import { authGuard, permissionsGuard } from '@/router/guards';

export const dashboardRoutes = [
  {
    path: '',
    name: 'dashboard',
    component: Home,
    beforeEnter: authGuard,
    children: [
      {
        path: 'active-user-stories/:projectUserStoryId',
        name: 'dashboard_editProjectUserStory',
        component: () =>
          import(
            /* webpackChunkName: "editProjectUserStory" */ '@/views/project-user-stories/EditProjectUserStory.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          getBackRoute: () => ({
            name: 'dashboard',
          }),
        },
      },
      {
        path: 'project-issues/:projectIssueId',
        name: 'dashboard_editProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "editProjectIssue" */ '@/views/project-issues/EditProjectIssue.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          getBackRoute: () => ({
            name: 'dashboard',
          }),
        },
      },
      {
        path: 'todos/create',
        name: 'createTodo',
        component: () =>
          import(/* webpackChunkName: "createTodo" */ '@/views/todos/CreateTodo.vue'),
        beforeEnter: authGuard,
        meta: {
          getBackRoute: () => ({
            name: 'dashboard',
          }),
        },
      },
      {
        path: 'todos/:todoId/edit',
        name: 'editTodo',
        component: () => import(/* webpackChunkName: "editTodo" */ '@/views/todos/EditTodo.vue'),
        beforeEnter: authGuard,
        meta: {
          getBackRoute: () => ({
            name: 'dashboard',
          }),
        },
      },
    ],
  },
];
