import { permissionsGuard } from '@/router/guards';
import store from '@/store';

export const projectsStatisticsRoutes = [
  {
    path: 'project-statistics',
    name: 'projectStatisticsTabs',
    component: () =>
      import(
        /* webpackChunkName: "projectStatisticsTabs" */ '@/views/project-statistics/ProjectListStatisticsTabs.vue'
      ),
    beforeEnter: permissionsGuard,
    redirect: () => ({
      name: 'projectIssueStatistics',
      query: store.state.projectIssueStatistics.projectIssueStatisticsFilterParams,
    }),
    meta: {
      menuGroup: 'project-management',
      requiredPermissions: ['projects.view.*'],
    },
    children: [
      {
        path: 'issues',
        name: 'projectIssueStatistics',
        component: () =>
          import(
            /* webpackChunkName: "projectIssueStatistics" */ '@/views/project-statistics/ProjectIssueStatistics.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'employees',
        name: 'employeeIssueStatistics',
        component: () =>
          import(
            /* webpackChunkName: "employeeIssueStatistics" */ '@/views/project-statistics/EmployeeIssueStatistics.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'created-by',
        name: 'issueCreatedByStatistics',
        component: () =>
          import(
            /* webpackChunkName: "issueCreatedByStatistics" */ '@/views/project-statistics/IssueCreatedByStatistics.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'issue-statuses',
        name: 'issueStatusesStatistics',
        component: () =>
          import(
            /* webpackChunkName: "issueStatusesStatistics" */ '@/views/project-statistics/IssueStatusesStatistics.vue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
    ],
  },
];
