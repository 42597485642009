<template>
  <div class="d-flex align-center">
    <v-tooltip :disabled="!indicatorProps.tooltip" content-class="tooltip-in-dialog" bottom>
      <template #activator="{ on }">
        <div v-on="on" class="d-flex align-center justify-center" style="width: 36px; height: 36px">
          <v-btn
            v-if="!issue.is_resolved"
            :loading="updatingIsResolved"
            :disabled="updatingIsResolved"
            :class="buttonClass"
            :color="indicatorProps.color"
            icon
            @click.stop="toggleIsResolved"
          >
            <v-icon>{{ indicatorProps.icon }}</v-icon>
          </v-btn>

          <!-- Can't toggle an already resolved issue back to not resolved. -->
          <!-- So instead of a button we render an icon, in a button sized container, -->
          <!-- so there is no content shift when updating resolved status -->
          <v-icon v-else :class="buttonClass" :color="indicatorProps.color">{{
            indicatorProps.icon
          }}</v-icon>
        </div>
      </template>
      <div>
        {{ indicatorProps.tooltip }}
      </div>
    </v-tooltip>
    <div v-if="showLabel" class="ml-2 text-body-2">{{ label }}</div>
  </div>
</template>

<script>
import projectIssueService from '@/api/project-issue-service';
import { openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';

export default {
  name: 'ProjectIssueIsResolvedIndicator',

  props: {
    issue: {
      type: Object,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: [String, Array, Object],
      default: '',
    },
  },

  data() {
    return {
      updatingIsResolved: false,
    };
  },

  computed: {
    label() {
      if (this.issue.is_resolved) {
        return this.$t('projects.labels.issue_resolved_button_label');
      }

      return this.$t('projects.labels.issue_unresolved_button_label');
    },

    indicatorProps() {
      if (this.issue.is_resolved) {
        return {
          tooltip: this.showLabel ? '' : this.$t('projects.labels.issue_is_resolved'),
          icon: 'check_circle',
          color: 'success',
        };
      }

      return {
        tooltip: this.$t('projects.labels.mark_as_resolved'),
        icon: 'unpublished',
        color: 'error',
      };
    },
  },

  methods: {
    toggleIsResolved() {
      this.updatingIsResolved = true;
      projectIssueService
        .updateIsResolved(this.issue)
        .then((res) => {
          if (res.data.is_resolved) {
            openSnackbar(i18n.t('projects.labels.issue_marked_as_resolved'));
          } else {
            openSnackbar(i18n.t('projects.labels.issue_marked_as_not_resolved'));
          }
          this.$emit('updated', res.data);
        })
        .finally(() => {
          this.updatingIsResolved = false;
        });
    },
  },
};
</script>

<style scoped></style>
