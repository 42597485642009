export default {
  storeStrategyGoalCategories(state, strategyGoalCategories) {
    state.strategyGoalCategories = strategyGoalCategories;
  },

  storeStrategyGoalCategory(state, strategyGoalCategory) {
    state.strategyGoalCategories.push(strategyGoalCategory);
  },

  updateStrategyGoalCategory(state, payload) {
    state.strategyGoalCategories.splice(payload.index, 1, payload.item);
  },

  deleteStrategyGoalCategory(state, index) {
    state.strategyGoalCategories.splice(index, 1);
  },
};
