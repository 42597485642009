<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.per_page || 50],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="items"
    :mobile-breakpoint="0"
    :page="pagination.current_page || 1"
    :server-items-length="pagination.total || -1"
    :class="{ 'content-loading': loading }"
    :loading="loading"
    :item-class="() => 'sortable-content clickable relative'"
    disable-sort
    @click:row="$emit('click:row', $event)"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.title="{ item }">
      <div class="preserve-whitespace">
        {{ item.title }}
      </div>
    </template>

    <template v-slot:item.deadline_at="{ item }">
      <div :class="getDeadlinePassedClass(item.deadline_at)">{{ item.deadline_at }}</div>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :loading="$store.getters.loading[`delete:api/todos/${item.id}`]"
        :item="item"
      />
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '../../mixins/table-mixin.js';
import { isPast } from 'date-fns';
import Sortable from 'sortablejs';
import sleep from '../../util/sleep.js';
import BaseActionMenu from '../base/BaseActionMenu.vue';

export default {
  name: 'TodosTable',
  components: { BaseActionMenu },
  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sortable: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('general.title'),
          value: 'title',
        },
        {
          text: this.$t('general.deadline_at'),
          value: 'deadline_at',
        },
        {
          value: 'actions',
          align: this.$vuetify.breakpoint.smAndDown ? 'hidden' : '', // "hidden" puts text-hidden class on td to hide it
        },
      ];
    },
  },

  async mounted() {
    await sleep(500); // Timeout is needed for sortable to initialize correctly
    this.renderSortable();
  },

  methods: {
    async renderSortable() {
      const table = document.querySelector('tbody');
      this.sortable = new Sortable(table, {
        draggable: 'tr.sortable-content',
        direction: 'vertical',
        delay: 500,
        delayOnTouchOnly: true,
        animation: 150,
        onEnd: (e) => {
          this.$emit('sort', {
            newIndex: e.newIndex,
            oldIndex: e.oldIndex,
            todos: this.items,
          });
        },
      });
    },

    getRowActions() {
      return [
        {
          callback: (p) => this.$emit('delete', p),
          label: this.$t('general.controls.delete'),
          icon: 'delete',
        },
      ];
    },

    getDeadlinePassedClass(date) {
      if (!isPast(new Date(date))) {
        return '';
      }
      return 'error--text font-weight-medium';
    },
  },
};
</script>

<style scoped></style>
