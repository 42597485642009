<template>
  <v-card>
    <v-subheader class="d-flex align-center justify-space-between pr-2">
      <div class="d-flex align-center">
        <v-icon class="mr-2">checklist</v-icon>
        {{ $t('general.todo') }}
      </div>
      <v-btn right icon @click.stop="$router.push({ name: 'createTodo' })">
        <v-icon>add</v-icon>
      </v-btn>
    </v-subheader>

    <TodosTable
      :items="todos"
      :loading="$store.getters.loading['get:api/todos']"
      :pagination="todoPagination"
      @update:page="onPageChange"
      @click:row="onRowClick"
      @sort="onTodosSort"
      @delete="deleteTodo"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TodosTable from './tables/TodosTable.vue';

export default {
  name: 'Todos',

  components: { TodosTable },

  computed: {
    ...mapState('todos', ['todos', 'todoPagination']),
  },

  created() {
    this.fetchTodos({});
  },

  methods: {
    ...mapActions('todos', ['fetchTodos', 'sortTodos', 'deleteTodo']),

    onPageChange(page) {
      this.fetchTodos({ page });
    },

    onRowClick(item) {
      this.$router.push({ name: 'editTodo', params: { todoId: item.id } });
    },

    onTodosSort({ newIndex, oldIndex, todos }) {
      const sortedItem = todos[oldIndex];
      const sort = todos.map((todo) => todo.id);
      sort.splice(oldIndex, 1);
      sort.splice(newIndex, 0, sortedItem.id);
      this.sortTodos(sort);
    },
  },
};
</script>

<style scoped></style>
