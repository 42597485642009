import projectDocumentationService from '@/api/project-documentation-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { collectMultiSelectErrors, mapErrorsToInputs } from '@/util/forms';
import clone from 'just-clone';
import { removeArrayItem, updateArrayItem } from '@/util/array';

export const getDefaultProjectDocumentationFormItem = () => ({
  user_ids: [],
});

const state = {
  projectDocumentations: [],
  editedProjectDocumentation: {},
  newProjectDocumentation: getDefaultProjectDocumentationFormItem(),
  projectDocumentationValidationErrors: {},
};

const getters = {
  decoratedProjectDocumentations(state) {
    return state.projectDocumentations.map((documentation) => {
      const d = clone(documentation);
      d.permissions.sort((p1, p2) =>
        p1.user.person.full_name > p2.user.person.full_name ? 1 : -1
      );
      return d;
    });
  },
};

const mutations = {
  SET_PROJECT_DOCUMENTATIONS(state, payload) {
    state.projectDocumentations = payload;
  },

  STORE_PROJECT_DOCUMENTATION(state, item) {
    state.projectDocumentations.unshift(item);
    state.newProjectDocumentation = getDefaultProjectDocumentationFormItem();
  },

  UPDATE_PROJECT_DOCUMENTATION(state, projectDocumentation) {
    state.projectDocumentations = updateArrayItem(
      state.projectDocumentations,
      projectDocumentation
    );
  },

  SET_EDITED_PROJECT_DOCUMENTATION(state, projectDocumentation) {
    state.editedProjectDocumentation = clone(projectDocumentation);
    state.projectDocumentationValidationErrors = {};
  },

  SET_NEW_PROJECT_DOCUMENTATION(state, projectDocumentation) {
    state.newProjectDocumentation = projectDocumentation;
    state.projectDocumentationValidationErrors = {};
  },

  DELETE_PROJECT_DOCUMENTATION(state, projectDocumentation) {
    state.projectDocumentations = removeArrayItem(
      state.projectDocumentations,
      projectDocumentation
    );
  },

  SET_PROJECT_DOCUMENTATION_VALIDATION_ERRORS(state, errors) {
    const transformedErrors = clone(errors);
    transformedErrors.user_ids = collectMultiSelectErrors(errors, 'user_ids');
    state.projectDocumentationValidationErrors = transformedErrors;
  },

  CLEAR_PROJECT_DOCUMENTATION_VALIDATION_ERRORS(state, field) {
    delete state.projectDocumentationValidationErrors[field];
  },
};

const actions = {
  async fetchProjectDocumentations({ commit }, params) {
    const { data } = await projectDocumentationService.getAll(params);
    commit('SET_PROJECT_DOCUMENTATIONS', data);
  },

  storeProjectDocumentation({ commit }, { projectId, projectDocumentation }) {
    return projectDocumentationService
      .create(projectId, projectDocumentation)
      .then((res) => {
        commit('STORE_PROJECT_DOCUMENTATION', res.data);
        openSnackbar(i18n.t('projects.project_documentation_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_DOCUMENTATION_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editProjectDocumentation({ getters, commit }, { projectId, projectDocumentationId }) {
    const projectDocumentation = getters.decoratedProjectDocumentations?.find(
      (s) => s.id === projectDocumentationId
    );
    if (projectDocumentation) {
      commit('SET_EDITED_PROJECT_DOCUMENTATION', projectDocumentation);
      return Promise.resolve(projectDocumentation);
    }
    return projectDocumentationService.getById(projectId, projectDocumentationId).then((res) => {
      commit('SET_EDITED_PROJECT_DOCUMENTATION', res.data);
      return res.data;
    });
  },

  updateProjectDocumentation({ commit }, projectDocumentation) {
    return projectDocumentationService
      .update(projectDocumentation.project_id, projectDocumentation)
      .then((res) => {
        commit('UPDATE_PROJECT_DOCUMENTATION', res.data);
        openSnackbar(i18n.t('projects.project_documentation_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_DOCUMENTATION_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async moveProjectDocumentation({ state, commit }, { projectId, event }) {
    const projectDocumentationsBackup = clone(state.projectDocumentations);

    try {
      const projectDocumentations = clone(state.projectDocumentations);
      const movedProjectDocumentationId =
        +event.item.attributes['data-project-documentation-id'].value;
      const { oldIndex, newIndex } = event;

      if (oldIndex === newIndex) {
        return;
      }

      const movedProjectDocumentation = projectDocumentations.find(
        (s) => s.id === movedProjectDocumentationId
      );
      if (!movedProjectDocumentation) {
        throw new Error('Project documentation not found');
      }

      projectDocumentations.splice(oldIndex, 1);
      projectDocumentations.splice(newIndex, 0, movedProjectDocumentation);
      commit('SET_PROJECT_DOCUMENTATIONS', projectDocumentations);

      await projectDocumentationService.reorder(projectId, {
        documentation_ids: projectDocumentations.map((pd) => pd.id),
      });
    } catch (e) {
      commit('SET_PROJECT_DOCUMENTATIONS', projectDocumentationsBackup);
      openSnackbar(i18n.t('projects.failed_to_move_project_documentation'));
      throw e;
    }
  },

  async deleteProjectDocumentation({ commit }, projectDocumentation) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    });
    if (!confirmed) {
      return 'cancelled';
    }

    await projectDocumentationService.delete(projectDocumentation.project_id, projectDocumentation);
    openSnackbar(i18n.t('projects.project_documentation_deleted'));
    commit('DELETE_PROJECT_DOCUMENTATION', projectDocumentation);
    return 'deleted';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
