import httpClient from '@/api/http-client';

const endpoint = 'api/clients';

const contactService = {
  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  searchSuppliers: (query) =>
    httpClient.get(`${endpoint}/find/${query}`, { params: { type: 'supplier' } }),
};

export default contactService;
