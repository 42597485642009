import moment from 'moment';
import Vue from 'vue';
import { openSnackbar } from '@/util/event-bus';
import httpClient from '@/api/http-client';
import { removeArrayItem } from '@/util/array';

const state = {
  currentEmployee: {
    user: {},
    personal_data: {},
    employment_history: { salary_is_fixed: false },
    absence_history: [],
    employment_hired: {},
    accounts: [],
    is_active: true,
  },
  newVacationApplication: {
    start: moment().day(8).format('YYYY-MM-DD'), // from next monday
    end: '',
    days: 5,
    type: 'vacation_paid',
  },
  userPhoto: new Blob(),
  absenceHistory: [],
  employmentHistory: [],
  employeeDocuments: [],
  employmentEvent: { is_approved: false },
  absenceEvent: { is_approved: false },
  currentIndex: -1,
  employmentHistoryIndex: -1,
  employeeAbsenceIndex: -1,
  employees: [],
  currentDocumentsPage: 1,
  hideFiredEmployees: true,
  totalActiveEmployees: 0,
  totalEmployeeSalary: 0,
  permissionDialog: false,
  employeeEditDialog: false,
  employmentHistoryDialog: false,
  absenceHistoryDialog: false,
  vacationDialog: false,
  tempEmployee: {},
};

const getters = {
  tempEmployee(state) {
    return JSON.parse(JSON.stringify(state.tempEmployee));
  },
  employee(state) {
    return state.currentEmployee;
  },
  employees(state) {
    return state.employees;
  },
  employeeCurrentPage(state) {
    return state.currentPage;
  },
  employeeHideFiredEmployees(state) {
    return state.hideFiredEmployees;
  },
  totalActiveEmployees(state) {
    return state.totalActiveEmployees;
  },
  totalEmployeeSalary(state) {
    return state.totalEmployeeSalary;
  },
  employeeEditDialog(state) {
    return state.employeeEditDialog;
  },
  permissionDialog(state) {
    return state.permissionDialog;
  },
  employmentHistoryDialog(state) {
    return state.employmentHistoryDialog;
  },
  absenceHistoryDialog(state) {
    return state.absenceHistoryDialog;
  },
  vacationDialog(state) {
    return state.vacationDialog;
  },
  employeeIndex(state) {
    return state.currentIndex;
  },
  employmentHistoryIndex(state) {
    return state.employmentHistoryIndex;
  },
  employeeAbsenceIndex(state) {
    return state.employeeAbsenceIndex;
  },
  employeeNewVacationApplication(state) {
    return state.newVacationApplication;
  },
  employmentEvent(state) {
    return JSON.parse(JSON.stringify(state.employmentEvent));
  },
  absenceEvent(state) {
    return JSON.parse(JSON.stringify(state.absenceEvent));
  },
  absenceHistoryEvents(state) {
    return state.absenceHistory;
  },
  employmentHistoryEvents(state) {
    return state.employmentHistory;
  },
  employeeDocuments(state) {
    return state.employeeDocuments;
  },
  employeePhoto(state) {
    return state.userPhoto;
  },
};

const mutations = {
  storeEmployees(state, employees) {
    state.employees = employees;
  },

  setCurrentPage(state, page) {
    state.currentPage = page;
  },

  toggleFiredEmployees(state) {
    state.hideFiredEmployees = !state.hideFiredEmployees;
  },

  setEmployeesTotals(state, data) {
    state.totalActiveEmployees = data.totalActiveEmployees;
    state.totalEmployeeSalary = data.totalEmployeeSalary;
  },

  setEmployee(state, employee) {
    state.currentEmployee = JSON.parse(JSON.stringify(employee));
    const lastIndex = employee.employment_history.length - 1;
    if (lastIndex > -1) {
      state.employmentEvent = JSON.parse(JSON.stringify(employee.employment_history[lastIndex]));
    }
  },

  setDialog(state, data) {
    // if (data.value) window.location.hash = 'modal';

    state[data.dialog] = data.value;

    if (!data.value && data.reset) {
      state.currentEmployee = {
        user: {},
        personal_data: {},
        employment_history: { salary_is_fixed: false },
        absence_history: [],
        employment_hired: {},
        accounts: [],
        is_active: true,
        gender: 'male',
      };
      state.currentIndex = -1;
      state.employmentHistoryIndex = -1;
      state.employeeAbsenceIndex = -1;
    }
  },

  setEmployeeIndex(state, value) {
    state.currentIndex = value;
  },

  setEmploymentHistoryIndex(state, value) {
    state.employmentHistoryIndex = value;
  },
  setEmploymentAbsenceIndex(state, value) {
    state.employeeAbsenceIndex = value;
  },

  updateEmployeesTable(state, employee) {
    if (state.currentIndex > -1) {
      Vue.set(state.employees, state.currentIndex, employee);
    } else {
      state.employees.push(employee);
    }
  },

  updateEmploymentHistoryEvent(state, data) {
    const itemToUpdate = state.employmentHistory.find((item) => item.id === data.id);
    if (itemToUpdate) {
      const array = state.employmentHistory.slice();
      const index = array.indexOf(itemToUpdate);
      array[index] = data;
      state.employmentEvent = data;
      state.employmentHistory = array;

      const lastIndex = state.employmentHistory.length - 1;
      if (index === lastIndex && data.event === 'hired')
        state.currentEmployee.employment_hired = data;
    }
  },

  createEmploymentHistoryEvent(state, data) {
    state.currentEmployee.employment_hired = data;
    const array = state.employmentHistory.slice();
    array.push(data);
    state.employmentEvent = data;
    state.employmentHistory = array;
  },

  updateAbsenceHistoryEvent(state, data) {
    const itemToUpdate = state.absenceHistory.find((item) => item.id === data.id);
    if (itemToUpdate) {
      const array = state.absenceHistory.slice();
      const index = array.indexOf(itemToUpdate);
      array[index] = data;
      state.absenceEvent = data;
      state.absenceHistory = array;
    }
  },

  createAbsenceHistoryEvent(state, data) {
    const array = state.absenceHistory.slice();
    array.push(data);
    state.absenceEvent = data;
    state.absenceHistory = array;
  },

  setEmploymentEvent(state, event) {
    state.employmentEvent = JSON.parse(JSON.stringify(event));
  },

  setAbsenceEvent(state, event) {
    state.absenceEvent = JSON.parse(JSON.stringify(event));
  },

  setEmploymentHistoryEvents(state, events) {
    state.employmentHistory = events;
  },

  setAbsenceHistoryEvents(state, events) {
    state.absenceHistory = events;
  },

  deleteAbsenceHistoryEvent(state, event) {
    state.absenceHistory = removeArrayItem(state.absenceHistory, event);
  },

  setEmployeeDocuments(state, documents) {
    state.employeeDocuments = documents;
  },

  setEmploymentHired(state, data) {
    const employees = state.employees.slice();
    const employee = employees.find((emp) => emp.id === state.currentEmployee.id);

    const array = employee.employment_history.slice();
    const lastIndex = array.length - 1;
    if (lastIndex > -1) {
      array[lastIndex] = data;
      employee.employment_history = array;
    }
    employees[state.currentIndex] = employee;
    state.employees = employees;

    state.currentEmployee.employment_history = data;

    if (data.event === 'hired') {
      state.currentEmployee.hired_date = data.date;
    }
  },

  setPersonalData(state, data) {
    state.currentEmployee.personal_data = data;
  },

  setPhoto(state, photo) {
    state.userPhoto = photo;
  },

  resetEmployee(state) {
    state.currentEmployee = {
      user: {},
      personal_data: {},
      employment_history: { salary_is_fixed: false },
      absence_history: [],
      employment_hired: {},
      accounts: [],
      is_active: true,
      create_encryption_key: true,
      employee_cr_key: true,
      encrypt: true,
      gender: 'male',
    };
    state.currentIndex = -1;
    state.employmentHistoryIndex = -1;
    state.employeeAbsenceIndex = -1;
  },

  setTempEmployee(state) {
    state.tempEmployee = JSON.parse(JSON.stringify(state.currentEmployee));
  },
};

const actions = {
  getEmployees({ commit, state, dispatch }) {
    return new Promise((resolve) => {
      httpClient
        .get(`api/employees?inactive=${state.hideFiredEmployees}&page=${state.currentPage}`)
        .then((response) => {
          commit('setCurrentPage', response.data.current_page);
          commit('storeEmployees', response.data.data);
          commit('resetEmployee');
          dispatch('getEmployeeTotals');
          resolve(response.data);
        });
    });
  },

  getEmployee({ commit, dispatch }, { id, decrypt }) {
    let decryptFilter = '';
    if (decrypt) {
      decryptFilter = `?decrypt=${decrypt}&employee_cr_key=true`;
    }
    return new Promise((resolve) => {
      httpClient.get(`api/employees/${id}${decryptFilter}`).then((resp) => {
        commit('setEmployee', resp.data);
        commit('setEmployeeIndex', 0);
        dispatch('getEmploymentHistory');
        resolve(resp.data.person_id);
      });
    });
  },

  getPhoto({ commit }, id) {
    httpClient.get(`api/person-photo/${id}`, { responseType: 'blob' }).then((response) => {
      commit('setPhoto', response.data);
    });
  },

  getEmployeeTotals({ commit }) {
    httpClient.get('api/employees/totals').then((response) => {
      commit('setEmployeesTotals', {
        totalActiveEmployees: response.data.total_active_employees,
        totalEmployeeSalary: response.data.total_salary,
      });
    });
  },

  previewDocument(context, url) {
    httpClient
      .post(
        `api/${url}?save=false`,
        {},
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const fileURL = URL.createObjectURL(response.data);
        window.open(fileURL);
      });
  },

  saveDocument(context, url) {
    httpClient
      .post(
        `api/${url}?save=true`,
        {},
        {
          responseType: 'blob',
        }
      )
      .then(() => {
        openSnackbar('Dokumentas išsaugotas.');
      });
  },

  createEmployeeDocumentPackage({ state }) {
    const fileName = `${state.currentEmployee.personal_data.first_name}_${state.currentEmployee.personal_data.last_name}_dokumentu_paketas.zip`;
    httpClient
      .get(`api/employee-documents-package/${state.currentEmployee.user.id}`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
  },

  getEmploymentHistory({ commit, state }) {
    httpClient
      .get(`api/employees/employment-history/${state.currentEmployee.id}`)
      .then((response) => {
        commit('setEmploymentHistoryEvents', response.data);
      });
  },

  getAbsenceHistory({ commit, state }) {
    httpClient.get(`api/employees/absence-history/${state.currentEmployee.id}`).then((response) => {
      commit('setAbsenceHistoryEvents', response.data);
    });
  },

  getDocuments({ commit, state }, filters) {
    let fullFilterPath = '';
    if (filters) fullFilterPath = `&${filters}`;

    return new Promise((resolve) => {
      if (state.currentEmployee.id) {
        httpClient
          .get(
            `api/documents?document_type=employee&page=${state.currentDocumentsPage}&employee_id=${state.currentEmployee.id}${fullFilterPath}`
          )
          .then((response) => {
            commit('storeDocuments', response.data.data);
            resolve(response.data);
          });
      }
    });
  },

  downloadAll({ state }, filters) {
    const fullFilterPath = `&download=true&${filters}`;
    httpClient
      .get(
        `api/documents?document_type=employee&page=${state.currentDocumentsPage}&employee_id=${state.currentEmployee.id}${fullFilterPath}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${state.currentEmployee.personal_data.first_name}_${state.currentEmployee.personal_data.last_name}.zip`
        );
        document.body.appendChild(link);
        link.click();
      });
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
