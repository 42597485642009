<template>
  <v-container
    v-if="
      $store.getters['auth/currentUser'] && $store.getters['auth/currentUser'].role === 'client'
    "
  >
    <v-row dense>
      <h1 class="text-h6 pa-3">{{ $t('general.welcome_to_evolco_client_portal') }}.</h1>
    </v-row>
  </v-container>

  <v-container v-else-if="!loadingInitialData" fluid>
    <v-row dense>
      <v-col cols="12" lg="4">
        <v-row dense>
          <v-col v-if="displayUrgentWork" cols="12">
            <v-card>
              <div
                v-for="(userStories, projectName, index) in userStoriesByProject"
                :key="projectName"
                :class="{ 'mt-3': index > 0 }"
              >
                <v-subheader>
                  <v-icon class="mr-2">offline_bolt</v-icon>
                  {{ projectName }} {{ $t('general.urgent_user_stories').toLowerCase() }}
                </v-subheader>
                <ProjectUserStoriesList :user-stories="userStories" @click:row="onUserStoryClick" />
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <Todos />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="displayNewIssues" cols="12" lg="8">
        <v-expansion-panels v-model="expandedPanels" mandatory>
          <ProjectIssuesPanel show-project-links @click:issue="onIssueClick">
            <template v-slot:title>
              <v-subheader class="px-0 default-cursor">
                <v-icon class="mr-2">bug_report</v-icon>
                {{ $t('general.issues') }}
              </v-subheader>
            </template>
          </ProjectIssuesPanel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-divider v-if="displayUrgentWork || displayNewIssues" class="my-4" />

    <OngoingProjectSprints
      v-if="displayOngoingSprints"
      :items="ongoingSprints"
      @move:sprint="moveSprint"
    />
    <v-divider v-if="displayOngoingSprints" class="my-4" />

    <v-row dense>
      <v-col v-if="$store.state.settings.miniWiki" cols="12" lg="4">
        <v-row dense>
          <v-col id="mini-wiki" v-if="$store.state.settings.miniWiki" cols="12">
            <MiniWiki />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="$store.state.settings.upcomingEvents" cols="12" lg="4">
        <v-row dense>
          <v-col
            id="upcoming-events"
            v-if="$store.state.settings.upcomingEvents"
            :class="{ 'pa-0': !upcomingEvents.length }"
            cols="12"
          >
            <UpcomingCalendarEvents :items="upcomingEvents" />
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="$store.state.settings.devRant" cols="12" lg="4">
        <v-row dense>
          <v-col v-if="$store.state.settings.devRant" cols="12">
            <DevRantFeed />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <router-view />
  </v-container>
</template>

<script>
import DevRantFeed from '../components/homepage-components/DevRantFeed.vue';
import MiniWiki from '../components/homepage-components/MiniWiki.vue';
import OngoingProjectSprints from '../components/OngoingProjectSprints.vue';
import UpcomingCalendarEvents from '../components/homepage-components/UpcomingCalendarEvents.vue';
import ProjectUserStoriesList from '@/components/ProjectUserStoriesList.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { hideTopProgressBar, showTopProgressBar } from '@/util/event-bus';
import httpClient from '@/api/http-client';
import projectSprintService from '@/api/project-sprint-service';
import ProjectIssuesPanel from '@/views/project-issues/ProjectIssuesPanel.vue';
import { getCachedProjectIssueFilterParams } from '@/store/modules/project-issues-module';
import Todos from '../components/Todos.vue';
import { openReactPortal } from '@/util/react-portal-integration';

export default {
  name: 'Dashboard',

  components: {
    Todos,
    ProjectIssuesPanel,
    ProjectUserStoriesList,
    OngoingProjectSprints,
    DevRantFeed,
    MiniWiki,
    UpcomingCalendarEvents,
  },

  data() {
    return {
      expandedPanels: [0],
      newIssues: [],
      ongoingSprints: [],
      upcomingEvents: [],
      urgentUserStories: [],
      loadingInitialData: true,
    };
  },

  computed: {
    ...mapState('projectIssues', ['projectIssues']),
    ...mapGetters(['loading']),

    userStoriesByProject() {
      const userStoriesByProject = {};
      this.urgentUserStories.forEach((story) => {
        if (!userStoriesByProject[story.project.title]) {
          userStoriesByProject[story.project.title] = [];
        }
        userStoriesByProject[story.project.title].push(story);
      });
      return userStoriesByProject;
    },

    displayNewIssues() {
      return this.$store.state.settings.newIssues;
    },

    displayUrgentWork() {
      return this.$store.state.settings.urgentUserStories && this.urgentUserStories.length > 0;
    },

    displayOngoingSprints() {
      return this.$store.state.settings.ongoingSprints && this.ongoingSprints.length > 0;
    },
  },

  async created() {
    if (!this.$isClient()) {
      const requests = [this.getVariousEvents()];
      if (this.displayNewIssues) {
        requests.push(this.fetchProjectIssues(getCachedProjectIssueFilterParams()));
      }
      showTopProgressBar();
      await Promise.allSettled(requests);
      this.loadingInitialData = false;
      hideTopProgressBar();
    } else if (this.$can(['projects.view.*'])) {
      this.$router.replace('/client-projects');
    }
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_HIGHLIGHTED_USER_STORY_ID']),
    ...mapActions('projectIssues', ['fetchProjectIssues']),

    getVariousEvents() {
      return httpClient.get('api/various-events').then((response) => {
        this.upcomingEvents = response.data.events;
        this.urgentUserStories = response.data.project_user_stories_urgent;
        this.ongoingSprints = response.data.sprints;
      });
    },

    async moveSprint(movedSprint, insertBefore, newStatus) {
      movedSprint.test = 'pro';
      const sprintsBeforeUpdate = [...this.ongoingSprints];

      for (let i = 0; i < this.ongoingSprints.length; i++) {
        if (this.ongoingSprints[i].id === movedSprint.id) {
          this.ongoingSprints.splice(i, 1);
          i--;
        } else if (this.ongoingSprints[i].id === insertBefore?.id) {
          this.ongoingSprints = [
            ...this.ongoingSprints.slice(0, i),
            { ...movedSprint, status: newStatus },
            ...this.ongoingSprints.slice(i),
          ];
          i++;
        }
      }

      if (!insertBefore) {
        // inserted as last element
        this.ongoingSprints.push({ ...movedSprint, status: newStatus });
      }

      try {
        await projectSprintService.updatePriorities(this.ongoingSprints);
      } catch (e) {
        this.ongoingSprints = sprintsBeforeUpdate;
        return;
      }

      if (movedSprint.status === newStatus) {
        return;
      }

      try {
        await projectSprintService.update({
          ...movedSprint,
          status: newStatus,
        });
      } catch (e) {
        this.ongoingSprints = sprintsBeforeUpdate;
      }
    },

    onIssueClick(issue) {
      openReactPortal(`project/${issue.project_id}/issues/${issue.id}/edit`);
    },

    onUserStoryClick(item) {
      openReactPortal(`project/${item.project_id}/user-stories/${item.id}`);
    },
  },
};
</script>
