import general from './translations/_general.json';
import calendar from './translations/calendar';
import candidates from './translations/candidates';
import leads from './translations/leads';
import client_survey from './translations/client-survey';
import client_testimonials from './translations/client-testimonials';
import company_management from './translations/company-management';
import documents from './translations/documents';
import employees from './translations/employees';
import errors from './translations/error-messages';
import expenses from './translations/expenses';
import filters from './translations/filters';
import finance_planner from './translations/finance-planner';
import invoices from './translations/invoices';
import notifications from './translations/notifications';
import password_manager from './translations/password-manager';
import permissions from './translations/permissions';
import profile from './translations/profile';
import projects from './translations/projects';
import project_warranties from './translations/project-warranties';
import suppliers from './translations/suppliers';
import store from './translations/vuex-store';
import home_page from './translations/home-page';
import time_tracking from './translations/time-tracking';
import users from './translations/users';

export default {
  general,
  calendar,
  candidates,
  leads,
  client_survey,
  client_testimonials,
  company_management,
  documents,
  employees,
  errors,
  expenses,
  filters,
  finance_planner,
  home_page,
  invoices,
  notifications,
  password_manager,
  permissions,
  profile,
  projects,
  project_warranties,
  suppliers,
  store,
  time_tracking,
  users,
};
