import strategyGoalActions from './strategy-goals/actions';
import strategyActionActions from './strategy-actions/actions';
import strategyGoalCategoryActions from './strategy-goal-categories/actions';

import strategyGoalMutations from './strategy-goals/mutations';
import strategyActionMutations from './strategy-actions/mutations';
import strategyGoalCategoryMutations from './strategy-goal-categories/mutations';

const state = {
  strategyGoals: {
    0: [], // for storing recently created goals
    1: null,
    2: null,
    3: null,
    6: null,
    12: null,
    24: null,
    36: null,
    48: null,
    60: null,
  },
  selectedGoalStatus: 'active',
  timelineGoals: null,
  timelineYear: '',
  strategyActions: [],
  strategyGoalCategories: [],
  strategyGoalsReminder: [],
  strategyGoalDialog: false,
  strategyActionDialog: false,
  strategyGoalAssignDialog: false,
  strategyActionAssignDialog: false,
  periodStatusCounts: null,
  editedGoal: {},
  editedAction: {},
  defaultGoal: {},
  defaultAction: {},
  editedGoalIndex: -1,
  editedActionIndex: -1,
  editedGoalPeriod: -1,
};

const getters = {
  strategyGoalCategories(state) {
    return state.strategyGoalCategories;
  },

  strategyGoalsReminder(state) {
    return state.strategyGoalsReminder;
  },

  strategyGoals(state) {
    return state.strategyGoals;
  },

  timelineGoals(state) {
    return state.timelineGoals;
  },

  timelineYear(state) {
    return state.timelineYear;
  },

  strategyActions(state) {
    return state.strategyActions;
  },

  periodStatusCounts(state) {
    return state.periodStatusCounts;
  },
};

const mutations = {
  storeStatusCounts(state, payload) {
    state.periodStatusCounts = payload;
  },

  ...strategyGoalMutations,
  ...strategyActionMutations,
  ...strategyGoalCategoryMutations,
};

const actions = {
  storeStatusCounts({ commit }, payload) {
    commit('storeStatusCounts', payload);
  },

  ...strategyGoalActions,
  ...strategyActionActions,
  ...strategyGoalCategoryActions,
};

export default {
  state,
  getters,
  mutations,
  actions,
};
