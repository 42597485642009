import httpClient from '@/api/http-client';

const endpoint = 'api/todos';

const todosService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  create: (todo) => httpClient.post(endpoint, todo),

  update: (todo) => httpClient.put(`${endpoint}/${todo.id}`, todo),

  delete: (todo) => httpClient.delete(`${endpoint}/${todo.id}`),

  sort: (sort) => httpClient.patch(`${endpoint}/sort`, { sort }),
};

export default todosService;
