export default {
  storeStrategyActions(state, payload) {
    state.strategyActions = payload;
  },

  createStrategyAction(state, payload) {
    state.editedAction = payload.newItem;
    state.editedActionIndex = -1;
    state.strategyActionDialog = true;
  },

  strategyActionCreated(state, item) {
    Object.values(state.strategyGoals).forEach((periodGoals) => {
      periodGoals.forEach((goal) => {
        item.goals.forEach((assignedGoal) => {
          if (assignedGoal.id === goal.id) {
            goal.actions.push(item);
          }
        });
      });
    });
    state.strategyActions.push(item);
    if (state.strategyGoalDialog) {
      state.editedGoal.actions.push(item);
    }
    state.strategyActionDialog = false;
  },

  editStrategyAction(state, payload) {
    state.editedAction = JSON.parse(JSON.stringify(payload.item));
    state.defaultAction = JSON.parse(JSON.stringify(payload.item));
    state.editedActionIndex = payload.index;
    state.strategyActionDialog = true;
  },

  strategyActionUpdated(state, payload) {
    if (state.strategyGoalDialog) {
      Object.values(state.strategyGoals).forEach((periodGoals) => {
        periodGoals.forEach((goal) => {
          const foundIndex = goal.actions.findIndex(action => action.id === payload.item.id);
          if (foundIndex > -1) {
            goal.actions.splice(foundIndex, 1, payload.item);
          }
        });
      });
      state.editedGoal.actions.splice(payload.index, 1, payload.item);
    } else {
      state.strategyActions.splice(state.editedActionIndex, 1, payload.item);
    }
    if (!payload.keepDialogOpen) {
      state.strategyActionDialog = false;
    }
  },

  deleteStrategyAction(state, index) {
    state.strategyActions.splice(index, 1);
  },

  removeStrategyGoalAction(state, index) {
    state.editedGoal.actions.splice(index, 1);
    state.strategyGoals[state.editedGoalPeriod][state.editedGoalIndex].actions.splice(index, 1);
    state.strategyActionDialog = false;
  },
};
