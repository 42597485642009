<template>
  <v-slide-y-transition mode="out-in">
    <v-card v-if="rant" class="text-center">
      <v-img v-if="image" :src="image"></v-img>
      <v-card-text>
        <pre style="white-space: pre-wrap; overflow-wrap: break-word">{{ rant }}</pre>
        <div style="text-align: center">
          <small>
            <em><a href="https://devrant.com/feed" target="_blank">&#126;devrant.com</a></em>
          </small>
        </div>
      </v-card-text>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { hideTopProgressBar } from '@/util/event-bus';

export default {
  name: 'DevRantFeed',

  data() {
    return {
      image: '',
      rant: '',
    };
  },

  created() {
    this.getDevRantFeed();
  },

  methods: {
    getDevRantFeed() {
      this.$http.get('api/misc/dev-rant/newest').then((response) => {
        hideTopProgressBar();
        this.rant = response.data.rant;
        this.image = response.data.image;
      });
    },
  },
};
</script>

<style scoped>
a {
  color: #42b983;
}
</style>
