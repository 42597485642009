import clone from 'just-clone';
import { removeEmptyFilters } from '@/util/filter-params';
import { isArray } from '@/util/array';

// for use with filter components that wrap BaseFilterDialogAndChips.
// example: ReceivedInvoiceFilter.vue
// component using this mixin should have:
//   - a prop filterParams
//   - a prop or data defaultParams (object)
const filterMixin = {
  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      params: {},
      debounceTimerId: null,
      pendingAutocompleteValues: {}, // selected in filter but not yet submitted
      autocompleteValues: {}, //
    };
  },

  computed: {
    showResetButton() {
      return JSON.stringify(this.filterParams) !== JSON.stringify(this.defaultParams || {});
    },
  },

  created() {
    this.copyFilterParams();
  },

  beforeDestroy() {
    clearTimeout(this.debounceTimerId);
  },

  methods: {
    updateAutocompleteValue(key, item) {
      if (this.filterParams[key] === item?.id) {
        this.$set(this.autocompleteValues, key, item);
      }
      this.$set(this.pendingAutocompleteValues, key, item);
    },

    copyFilterParams() {
      this.params = clone(this.filterParams);
    },

    clearFilter(key, value) {
      if (isArray(this.params[key])) {
        this.params[key] = this.params[key].filter((item) => item !== value);
      } else {
        delete this.params[key];
      }
      this.submitFilters();
    },

    resetFilters() {
      this.params = clone(this.defaultParams);
      this.$emit('change', this.defaultParams || {});
    },

    submitFilters() {
      Object.keys(this.pendingAutocompleteValues).forEach((key) => {
        this.autocompleteValues[key] = this.pendingAutocompleteValues[key];
      });

      this.$emit('change', removeEmptyFilters(this.params));
    },

    handleDebounce() {
      // when using standalone search
      clearTimeout(this.debounceTimerId);
      this.debounceTimerId = setTimeout(() => {
        this.$emit('change', removeEmptyFilters(this.params));
      }, 500);
    },
  },
};

export default filterMixin;
