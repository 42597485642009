<template>
  <div>
    <div class="d-flex justify-end px-4 pt-4">
      <NewDashboardSwitch />
    </div>

    <DashboardAlternate v-if="showAlternateDashboard" />
    <Dashboard v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Dashboard from '@/views/Dashboard.vue';
import DashboardAlternate from '@/views/DashboardAlternate.vue';
import NewDashboardSwitch from '@/components/NewDashboardSwitch.vue';

export default {
  name: 'Home',

  components: { NewDashboardSwitch, DashboardAlternate, Dashboard },

  computed: {
    ...mapState('settings', ['showAlternateDashboard']),
  },
};
</script>
