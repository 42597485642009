<template>
  <v-container v-if="isDashboardLoaded && !$isClient()" fluid>
    <v-row dense>
      <v-col cols="12" md="6" order-md="2" xl="4" order-xl="3">
        <v-row dense>
          <v-col cols="12">
            <v-expansion-panels v-model="expandedPanels" mandatory>
              <ProjectIssuesPanel
                show-project-links
                is-always-expanded
                @click:issue="onProjectIssueClick"
              />
            </v-expansion-panels>
          </v-col>

          <v-col cols="12">
            <Todos />
          </v-col>
        </v-row>
      </v-col>

      <template v-if="$vuetify.breakpoint.xlOnly">
        <v-col cols="12" xl="4" order-xl="1">
          <ProjectSprintUserStoriesDashboardCard
            v-for="sprint in firstColumnSprints"
            :key="sprint.id"
            :sprint="sprint"
            :user-stories="projectUserStoriesBySprint[sprint.id]"
          />
        </v-col>

        <v-col cols="12" xl="4" order-xl="2">
          <ProjectSprintUserStoriesDashboardCard
            v-for="sprint in secondColumnSprints"
            :key="sprint.id"
            :sprint="sprint"
            :user-stories="projectUserStoriesBySprint[sprint.id]"
          />
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" md="6" order-md="1">
          <ProjectSprintUserStoriesDashboardCard
            v-for="sprint in sortedProjectSprints"
            :key="sprint.id"
            :sprint="sprint"
            :user-stories="projectUserStoriesBySprint[sprint.id]"
          />
        </v-col>
      </template>
    </v-row>

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { hideTopProgressBar, showTopProgressBar } from '@/util/event-bus';
import ProjectIssuesPanel from '@/views/project-issues/ProjectIssuesPanel.vue';
import { getCachedProjectIssueFilterParams } from '@/store/modules/project-issues-module';
import projectService from '@/api/project-service';
import ProjectSprintUserStoriesDashboardCard from '@/components/ProjectSprintUserStoriesDashboardCard.vue';
import clone from 'just-clone';
import Todos from '../components/Todos.vue';

export default {
  name: 'DashboardAlternate',

  components: { Todos, ProjectSprintUserStoriesDashboardCard, ProjectIssuesPanel },

  data() {
    return {
      expandedPanels: [0], // to ensure ProjectIssuesPanel is always expanded
      isDashboardLoaded: false,
      firstColumnSprints: [],
      secondColumnSprints: [],
    };
  },

  computed: {
    ...mapState('projectUserStories', ['projectUserStoriesBySprint']),
    ...mapState('projectSprints', ['projectSprints']),
    ...mapState('projectIssues', ['projectIssues']),

    sortedProjectSprints() {
      const sprints = clone(this.projectSprints);
      sprints.sort((s1, s2) => s1.priority - s2.priority);
      return sprints.map((sprint, sprintIndex) => ({
        ...sprint,
        priorityIndex: sprintIndex + 1,
      }));
    },
  },

  async created() {
    if (!this.$isClient()) {
      showTopProgressBar();
      await this.fetchAlternateDashboard();
      hideTopProgressBar();
    } else if (this.$can(['projects.view.*'])) {
      this.$router.replace('/client-projects');
    }
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_PROJECT_USER_STORIES_BY_SPRINT']),
    ...mapMutations('projectSprints', ['SET_PROJECT_SPRINTS']),
    ...mapActions('projectIssues', ['fetchProjectIssues']),

    distributeSprintsToColumns() {
      const columnItemCounts = [0, 0];
      const columnSprints = [[], []];

      for (let i = 0; i < this.sortedProjectSprints.length; i++) {
        const smallestColumn = Math.min(...columnItemCounts);
        const smallestColumnIndex = columnItemCounts.indexOf(smallestColumn);
        const sprint = this.sortedProjectSprints[i];
        columnSprints[smallestColumnIndex].push(sprint);
        columnItemCounts[smallestColumnIndex] +=
          this.projectUserStoriesBySprint[sprint.id].length || 1;
      }

      [this.firstColumnSprints, this.secondColumnSprints] = columnSprints;
    },

    async fetchAlternateDashboard() {
      const requests = [
        this.fetchActiveProjects(),
        this.fetchProjectIssues(getCachedProjectIssueFilterParams()),
      ];

      await Promise.allSettled(requests);
      this.distributeSprintsToColumns();
      this.isDashboardLoaded = true;
    },

    async fetchActiveProjects() {
      const { data } = await projectService.getActive();
      const sprints = [];
      const userStoriesBySprint = {};
      data.forEach((project) => {
        project.sprints.forEach((sprint) => {
          userStoriesBySprint[sprint.id] = sprint.user_stories;
          sprints.push({
            id: sprint.id,
            sprint_name: sprint.sprint_name,
            project_id: +sprint.project_id,
            priority: sprint.priority,
            project,
          });
        });
      });
      this.SET_PROJECT_SPRINTS(sprints);
      this.SET_PROJECT_USER_STORIES_BY_SPRINT(userStoriesBySprint);
    },

    onProjectIssueClick(issue) {
      this.$router.push({
        name: 'dashboard_editProjectIssue',
        params: { projectIssueId: issue.id },
      });
    },
  },
};
</script>

<style scoped></style>
